import confHomeCompounders from "../../conf/confHomeCompounders.json";
import apiRoutes from "../../api/apiRoutes";
import Vue from "vue";
import apiCalls from "@/api/apiCalls";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    suppliers: [],
    recipients: [],
    files: []
  };
};

const state = () => ({
  moduleName: "homeCompounders",
  formData: {
    suppliers: [],
    recipients: [],
    files: []
  },
  schema: { ...confHomeCompounders.schema },
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateHomeCompounders: ({ commit }, payload) => {
    commit("setUpdateHomeCompounders", payload);
    commit("setHideHomeCompounders");
  },

  /**
   * Add or update homeCompounders' module data.
   * Actual saving functionality.
   * */
  addHomeCompounders: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load homeCompounders' module data.
   * Done in homeCompounders' view created hook.
   * */
  loadHomeCompounders: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFileHomeCompounders: ({ state, rootState, rootGetters }, payload) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let existFiles = [...state.formData.files];
    let addFiles = payload;
    addFiles = addFiles.map(({ fieldName, stableID, fileName, uID }) => ({
      fieldName,
      stableID,
      fileName,
      uID
    }));
    addFiles.forEach(item => {
      existFiles.push(item);
    });
    let data = {
      token: token,
      moduleName: state.moduleName,
      payload: payload,
      existFiles: existFiles
    };
    apiCalls.addFile(data);
  },

  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFileHomeCompounders: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusHomeCompounders", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusHomeCompounders", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          let files = [...state.formData.files];
          files.splice(
            files.findIndex(function(i) {
              return i.uID === payload.uID;
            }),
            1
          );
          commit("setFileHomeCompounders", files);
          if (
            !!state.formData.hcRegistrationFileList &&
            state.formData.hcRegistrationFileList.length < 1
          ) {
            commit("setRegistrationFileListHomeCompounders");
          }
          dispatch("addHomeCompounders");
          commit("setStatusHomeCompounders", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusHomeCompounders", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  }
};

const mutations = {
  /**
   * If loadHomeCompounders returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataHomeCompounders(state) {
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confHomeCompounders.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateHomeCompounders(state, payload) {
    state.formData = payload;
  },

  /**
   * Set deletion of
   * */
  setRegistrationFileListHomeCompounders(state) {
    Vue.delete(state.formData, "hcRegistrationFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideHomeCompounders(state) {
    if (state.formData.hcProductionSiteChoose === true) {
      state.schema.hcInformation.hidden = true;
      [
        "hcPlantManager",
        "hcPmFirstName",
        "hcPmLastName",
        "hcPmPhone",
        "hcPmMobile",
        "hcPmFax",
        "hcPmEmail",
        "hcStreetNumber",
        "hcPostalCode",
        "hcLocation",
        "hcCountry",
        "hcRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.hcProductionSiteChoose === false) {
      state.schema.hcInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusHomeCompounders(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setHomeCompounders(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for file-objects.
   * */
  setFileHomeCompounders(state, payload) {
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for data due to approval.
   * */
  setEditableHomeCompounders(state, payload) {
    state.editable = payload;
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => HomeCompounders.vue
   * */
  getDataHomeCompounders: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => HomeCompounders.vue
   * */
  getSchemaHomeCompounders: state => {
    return state.schema;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList. Injected via
   * Injected via conf….json-file as a prop.
   * */
  getFilesHomeCompounders: state => {
    return state.formData.files;
  },

  /**
   * Get editability of data
   * */
  getEditableHomeCompounders: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
