import apiRoutes from "../../api/apiRoutes";
import confUserToCompany from "../../conf/confUserToCompany";
import router from "../../router/index";
import store from "@/store";

/* eslint-disable no-console */
const state = {
  moduleName: "auth",
  schema: { ...confUserToCompany.schema },
  formData: {
    companies: []
  },
  error: null,
  status: null,
  permission: 0 || localStorage.getItem("p"),
  loginToken: null || localStorage.getItem("token"),
  userToken: "" || localStorage.getItem("utoken"),
  userName: "" || localStorage.getItem("name"),
  locale: "" || localStorage.getItem("lang"),
  editable: true
};

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  updateUserToCompany: ({ commit }, payload) => {
    commit("set_updateUserToCompany", payload);
  },

  /**
   * Registers a new loginUser.
   * Registration-level 1, permission-level 1
   * */
  registerLogin({ commit }, userCredentials) {
    return new Promise((resolve, reject) => {
      commit("set_status", 1);
      apiRoutes
        .postRegisterLogin(userCredentials)
        .then(resp => {
          if (resp.data[0] === "ERROR") {
            commit("set_status", 3);
            commit("global_errorStatus", true, { root: true });
            commit(
              "global_error",
              { message: "errors.loginfailed" },
              { root: true }
            );
          } else {
            commit("set_status", 2);
            router.push("/");
            resolve(resp);
          }
        })
        .catch(err => {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.registrationerror" },
            { root: true }
          );
          reject(err);
        });
    });
  },

  /**
   * Registers a new companyUser.
   * Registration-level 2, permission-level 2
   * */
  registerCompany({ commit, dispatch, state }, companyCredentials) {
    const loginToken = state.loginToken;
    let companyPayload = {
      company: companyCredentials
    };
    return new Promise((resolve, reject) => {
      commit("set_status", 1);
      apiRoutes
        .postRegisterCompany(loginToken, companyPayload)
        .then(resp => {
          if (resp.data[0] === "ERROR") {
            commit("set_status", 3);
            commit("global_errorStatus", true, { root: true });
            commit(
              "global_error",
              { message: "errors.companyexists" },
              { root: true }
            );
          } else {
            commit("set_status", 2);
            dispatch("companyList");
            resolve(resp);
          }
        })
        .catch(err => {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.registrationerror" },
            { root: true }
          );
          reject(err);
        });
    });
  },

  /**
   * returns loginToken, locale, optionally companies
   * */
  loginUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("set_status", 1);
      apiRoutes
        .postLogin(user)
        .then(resp => {
          if (resp.data[0] === "ERROR") {
            commit("global_errorStatus", true, { root: true });
            commit(
              "global_error",
              { message: "errors.loginfailed" },
              { root: true }
            );
            commit("set_status", 3);
            commit("set_permission", 0);
          } else {
            const token = resp.data[1];
            const locale = resp.data[3];
            commit("global_clearError", null, { root: true });
            commit("set_authSuccess", token);
            if (locale === null || locale === undefined || locale === "") {
              commit("set_locale", "de");
            } else commit("set_locale", locale);
            commit("set_status", 2);
            commit("set_permission", 1);
            resolve();
          }
        })
        .catch(error => {
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.networkerror" },
            { root: true }
          );
          commit("set_status", 3);
          commit("set_permission", 0);
          commit("set_authLogout");
          reject(error);
        });
    });
  },

  passwordResetRequest({ commit }, payload) {
    commit("set_status", 1);
    apiRoutes
      .postPasswordResetRequest(payload)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit("global_error", { message: "errors.noData" }, { root: true });
        } else if (response.data[0] === "SUCCESS") {
          commit("set_status", 2);
          commit("global_successStatus", true, { root: true });
          commit(
            "global_success",
            { message: "resetPassword.passwordResetRequestSuccess" },
            { root: true }
          );
          setTimeout(() => {
            store.commit("global_clearSuccess", null, { root: true });
            router.push("/");
          }, 4000);
        } else {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.networkerror" },
            { root: true }
          );
        }
      })
      .catch(error => {
        console.log(error);
        commit("set_status", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  confirmPasswordReset({ commit }) {
    let payload = {
      hash: router.currentRoute.params.hash
    };
    commit("set_status", 1);
    apiRoutes
      .postConfirmPasswordReset(payload)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "resetPassword.passwordResetError" },
            { root: true }
          );
        } else if (response.data[0] === "EXPIRED") {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "resetPassword.passwordResetLinkExpired" },
            { root: true }
          );
        } else if (response.data[0] === "SUCCESS") {
          commit("set_status", 2);
          commit("global_successStatus", true, { root: true });
          commit(
            "global_success",
            { message: "resetPassword.passwordResetSuccess" },
            { root: true }
          );
          setTimeout(() => {
            store.commit("global_clearSuccess", null, { root: true });
            router.push("/");
          }, 4000);
        } else {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.networkerror" },
            { root: true }
          );
        }
      })
      .catch(error => {
        console.log(error);
        commit("set_status", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  companyList: ({ commit, state }) => {
    const token = state.loginToken;
    apiRoutes
      .getList(token, "usercompanies")
      .then(response => {
        if (response.data[0] === "ERROR") {
          console.log(response.data[1]);
        } else {
          let companies = response.data[1].map(obj => {
            return {
              name: obj["CompanyName"],
              token: obj["Token"],
              editable: obj["editable"] === "editable" ? true : false
            };
          });
          commit("set_companies", companies);
        }
      })
      .catch(error => {
        console.log(error);
      });
  },

  logout({ commit }) {
    return new Promise(resolve => {
      commit("set_authLogout");
      commit("set_status", 0);
      commit("set_permission", 0);
      commit("global_clearError", null, { root: true });
      commit("global_validateStatus", null, { root: true });
      resolve();
    });
  },

  logoutCompany: ({ commit, dispatch }) => {
    commit("set_companyLogout");
    commit("set_permission", 1);
    commit("global_clearError", null, { root: true });
    commit("global_validateStatus", null, { root: true });
    commit("validator/clear_ValidatorErrors", null, { root: true });
    dispatch("requestType/clearRequestTypeState", null, { root: true });
    commit("company/set_initialFormDataC", null, { root: true });
    dispatch("company/clearSingleLocationID", null, { root: true });
    commit("dealers/setInitialFormDataDealers", null, { root: true });
    commit("dyehouse/setInitialFormDataDyehouse", null, { root: true });
    commit("eggFoodFacility/setInitialFormDataEggFoodFacility", null, {
      root: true
    });
    commit("feedMills/setInitialFormDataFeedMills", null, { root: true });
    commit("hatchery/setInitialFormDataHatchery", null, { root: true });
    commit("homeCompounders/setInitialFormDataHomeCompounders", null, {
      root: true
    });
    commit("layingFarm/setInitialFormDataLayingFarm", null, { root: true });
    commit("packing/setInitialFormDataPacking", null, { root: true });
    commit("rearer/setInitialFormDataRearer", null, { root: true });
    commit("preraiser/setInitialFormDataPreraiser", null, { root: true });
    dispatch("send/clearSendState", null, { root: true });
  },

  changeLocale({ commit, dispatch }, locale) {
    commit("set_locale", locale);
    if (router.currentRoute.name === "company") {
      dispatch("loadCountryList", null, { root: true });
      dispatch("loadSalutationList", null, { root: true });
      dispatch("company/loadProductionTypesList", null, { root: true });
      dispatch("loadStableGenderTypeList", null, { root: true });
      dispatch("loadFarmingTypeRearerList", null, { root: true });
      dispatch("loadFarmingTypeLayingfarmList", null, { root: true });
      dispatch("loadRearingTypeList", null, { root: true });
    }
  },

  /**
   * Adds the userToken and name of selected
   * item/company in order to provide the correct token
   * for further navigation.
   * */
  addUserToken: ({ commit }, payload) => {
    if (payload !== undefined || payload !== null) {
      commit("set_userTokenName", payload);
      commit("set_permission", 2);
    } else {
      commit("set_userTokenName", null);
    }
  },

  /**
   * Deletes a user aka. company and its related data,
   * if company is not approved yet.
   * */
  deleteCompany({ commit, dispatch, state }, payload) {
    const userToken = payload;
    const loginToken = state.loginToken;
    commit("set_status", 1);
    apiRoutes
      .getDeleteCompany(userToken, loginToken)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          dispatch("companyList");
          commit("set_status", 2);
          commit("set_permission", 1);
          commit("set_companyLogout");
        }
      })
      .catch(error => {
        console.log(error);
        commit("set_status", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  }
};

const mutations = {
  set_status(state, payload) {
    state.status = payload;
  },
  set_authSuccess(state, payload) {
    state.loginToken = payload;
    localStorage.setItem("token", payload);
  },
  set_authLogout(state) {
    state.loginToken = null;
    state.userToken = "";
    state.locale = "";
    state.permission = 0;
    state.userName = "";
    localStorage.removeItem("token");
    localStorage.removeItem("utoken");
    localStorage.removeItem("locationID");
    localStorage.removeItem("lang");
    localStorage.removeItem("p");
    localStorage.removeItem("name");
  },
  set_locale(state, payload) {
    state.locale = payload;
    // i18n.locale = state.locale;
    localStorage.setItem("lang", payload);
  },
  set_updateUserToCompany(state, payload) {
    state.formData = payload;
  },
  set_companies(state, payload) {
    state.formData.companies = payload;
  },
  /**
   * Sets userToken aka. companyToken.
   * See addUserToken.
   * */
  set_userTokenName(state, payload) {
    if (payload !== null) {
      state.userToken = payload.token;
      state.userName = payload.name;
      localStorage.setItem("utoken", payload.token);
      localStorage.setItem("name", payload.name);
    } else {
      state.userToken = "";
      state.userName = "";
      localStorage.removeItem("utoken");
      localStorage.removeItem("name");
    }
  },

  set_companyLogout(state) {
    state.userToken = "";
    state.userName = "";
    localStorage.removeItem("utoken");
    localStorage.removeItem("name");
    localStorage.removeItem("locationID");
  },

  set_permission(state, permission) {
    state.permission = permission;
    permission === 0
      ? localStorage.removeItem("p")
      : localStorage.setItem("p", permission);
  }
};

const getters = {
  error: state => state.error,

  isAuthenticated: state =>
    !!(state.loginToken && localStorage.getItem("token") !== null),

  isAuthenticatedCompanyLevel: state => {
    return !!(state.loginToken && state.userToken);
  },

  authStatus: state => state.status,

  getToken: state => state.loginToken, // is used in apiClient.js

  getCompanyToken: state => state.userToken,

  getUserName: state => state.userName,

  getLang: state => state.locale,

  get_permission(state) {
    return state.permission;
  },

  /**
   * Getter for formData-object.
   * Used in views => UserToCompany.vue
   * */
  get_userToCompanyFormData: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => UserToCompany.vue
   * */
  get_userToCompanySchema: state => {
    return state.schema;
  },

  get_userToCompanyEditable: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
