<template>
  <v-app>
    <core-navigation ref="drawer" v-if="isAuthenticated" />

    <core-appbar @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer" />

    <core-view />

    <core-footer />
  </v-app>
</template>

<script>
/* eslint-disable no-console */
import CoreView from "./components/core/CoreView";
import CoreAppbar from "./components/core/CoreAppbar";
import CoreNavigation from "./components/core/CoreNavigation";
import CoreFooter from "./components/core/CoreFooter";
import { mapActions, mapGetters } from "vuex";
import confCore from "@/conf/confCore.json";

export default {
  name: "App",
  components: { CoreFooter, CoreAppbar, CoreView, CoreNavigation },

  data: () => ({
    appbarTitle: confCore.appbarTitle,
    initialLocale: "" //needed to compare against in watch
  }),

  computed: {
    ...mapGetters("auth", ["isAuthenticated", "getLang"])
  },
  created() {
    this.checkCurrentLogin();
  },
  updated() {
    this.checkCurrentLogin();
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$root.$i18n.locale = this.getLang;
    }
  },
  watch: {
    getLang: function(val) {
      if (
        val !== this.initialLocale &&
        (this.$route.path === "/" || this.$route.path === "/registration")
      ) {
        this.switchTitle(val);
      }
    },
    isAuthenticated: function(val) {
      if (val === false) {
        this.logout();
        this.$router.push("/").catch(e => e);
      }
    },
    // deep: true,
    immediate: true
  },
  methods: {
    ...mapActions("auth", ["logout"]),

    checkCurrentLogin() {
      if (this.isAuthenticated && this.$route.path !== "/") {
        this.$router.replace(this.$route.path).catch(err => err);
      } else if (!this.isAuthenticated && this.$route.path !== "/") {
        /*console.log("Rausschmiß erfolgte durch checkCurrentLogin in App.vue");*/
        this.logout();
        this.$router.push("/").catch(error => error);
      }
    },
    switchTitle(val) {
      switch (val) {
        case "de":
          document.title = `${this.$t(this.appbarTitle)}`;
          break;
        case "en":
          document.title = `${this.$t(this.appbarTitle)}`;
          break;
        case "nl":
          document.title = `${this.$t(this.appbarTitle)}`;
          break;
        default:
          document.title = "Registration";
      }
    }
  }
};
</script>
