import confSend from "../../conf/confSend";
import apiRoutes from "../../api/apiRoutes";
import i18n from "../../plugins/i18n";
import Vue from "vue";

/* eslint-disable no-console */
const initialState = () => {
  return {
    moduleName: "send",
    formData: {},
    schema: { ...confSend.schema },
    submissionDate: "",
    updateSubmissionDate: "",
    editable: true,
    status: 0,
    error: null
  };
};

const state = () => ({
  moduleName: "send",
  formData: {},
  schema: { ...confSend.schema },
  submissionDate: "",
  updateSubmissionDate: "",
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateSend: ({ commit }, payload) => {
    commit("setUpdateSend", payload);
    commit("setHideSend");
  },

  /**
   * Save or update send's module data.
   * Takes place, when submit-button is pushed.
   * */
  addSend({ commit, dispatch, state, rootState }) {
    const token = rootState.auth.userToken;
    commit("setStatusSend", 1);
    apiRoutes
      .postAddModuleData(token, state.moduleName, state.formData)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusSend", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrorsave" },
            { root: true }
          );
        } else {
          dispatch("sendFinalize");
          commit("setStatusSend", 2);
          commit("global_successStatus", true, { root: true });
          commit("global_success", true, { root: true });
          setTimeout(() => {
            commit("global_clearSuccess", null, { root: true });
          }, 4000);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusSend", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },
  /**
   * Submit and finalize.
   * Sets the submission date
   * */
  sendFinalize({ commit, dispatch, rootState }) {
    const token = rootState.auth.userToken;
    commit("setStatusSend", 1);
    apiRoutes
      .getSubmitRegistration(token)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusSend", 3);
          commit("global_errorStatus", true, { root: true });
          if (response.data[1].substring(0, 23) === "data already submitted:") {
            commit(
              "global_error",
              {
                message:
                  i18n.t(`send.dataAlreadySubmitted`) +
                  " " +
                  response.data[1].substring(24, 34)
              },
              { root: true }
            );
          } else
            commit(
              "global_error",
              { message: response.data[1] },
              { root: true }
            );
        } else {
          dispatch("loadSend");
          commit("setStatusSend", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusSend", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Load send's module data.
   * Done in sends's view created hook.
   * */
  loadSend: ({ commit, state, rootState, dispatch }) => {
    const token = rootState.auth.userToken;
    commit("setStatusSend", 1);
    apiRoutes
      .getModuleData(token, state.moduleName)
      .then(response => {
        if (response.data[0] === "ERROR") {
          if (response.data[1] === "no moduledata found") {
            commit("setInitialStateSend");
            commit("setStatusSend", 2);
          } else commit("setStatusSend", 3);
        } else {
          let moduleData = JSON.parse(response.data[2]);
          commit("setSend", moduleData);
          commit("setHideSend");
          commit("setStatusSend", 2);
          dispatch("loadSubmissionDate");
          dispatch("loadUpdateSubmissionDate");
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusSend", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Loads the finalization/submission-date.
   * As this action is dispatched at the
   * loadSend-action no errors and status
   * are committed.
   * */
  loadSubmissionDate({ commit, rootState }) {
    const token = rootState.auth.userToken;
    apiRoutes
      .loadSubmissionDate(token)
      .then(response => {
        if (response.data[0] === "SUCCESS") {
          commit("setSubmissionDateSend", response.data[1].substring(16, 26));
        }
      })
      .catch(error => {
        console.log(error);
      });
  },

  /**
   * Loads the finalization/submission update-dates.
   * As this action is dispatched at the
   * loadSend-action no errors and status
   * are committed.
   * */
  loadUpdateSubmissionDate({ commit, rootState }) {
    const token = rootState.auth.userToken;
    apiRoutes
      .loadUpdateSubmissionDate(token)
      .then(response => {
        if (response.data[0] === "SUCCESS") {
          commit("setUpdateSubmissionDateSend", response.data[1]);
        }
      })
      .catch(error => {
        console.log(error);
      });
  },

  /**
   * Done on company/user-logout in auth-module.
   * */
  clearSendState: ({ commit }) => {
    commit("setInitialStateSend");
  }
};

const mutations = {
  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateSend(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideSend(state) {
    if (
      state.formData.confirmInformation === false ||
      state.formData.privacyPolicy === false ||
      !state.formData.confirmInformation ||
      !state.formData.privacyPolicy
    ) {
      state.schema.sendSubmitBtn.disabled = true;
    }
    if (
      state.formData.confirmInformation === true &&
      state.formData.privacyPolicy === true
    ) {
      state.schema.sendSubmitBtn.disabled = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusSend(state, payload) {
    state.status = payload;
  },

  /**
   * Setter to change formData-object
   * upon any api-call.
   * */
  setSend(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter to change submissionDate-object
   * upon any api-call.
   * */
  setSubmissionDateSend(state, payload) {
    state.submissionDate = payload;
  },

  /**
   * Setter for submission update-dates.
   * */
  setUpdateSubmissionDateSend(state, payload) {
    let temp = payload.replace(/([\d])\s([\d])/g, "$1\xa0-\xa0$2");
    state.updateSubmissionDate = temp.replace(/([\d]),([\d])/g, "$1, $2");
  },

  setInitialStateSend(state) {
    state = Object.assign({}, initialState());
    Vue.set(state.schema.sendSubmitBtn, "disabled", true);
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => Send.vue
   * */
  getDataSend: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => Send.vue
   * */
  getSchemaSend: state => {
    return state.schema;
  },

  /**
   * Getter for submission date
   * */
  getSubmissionDateSend: state => {
    return state.submissionDate;
  },

  /**
   * Getter for submission update dates
   * */
  getUpdateSubmissionDateSend: state => {
    return state.updateSubmissionDate;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
