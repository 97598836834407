import apiRoutes from "../../api/apiRoutes";
import { isObject, hasKeys } from "../../helpers/helpers";
import Vue from "vue";
import confSend from "../../conf/confSend.json";
import {
  productionTypeToErrorMessage,
  productionTypeToStoreModule
} from "@/helpers/routeMapper";

/* eslint-disable no-console */
/*const initialState = {
  moduleName: "validator",
  schema: {},
  schemaFieldsToValidate: {},
  formDataToValidate: {},
  status: 0,
  validatorErrors: [],
  facilityDetails: confSend.content.missingFacilityDetails
};*/

const state = () => ({
  moduleName: "validator",
  schema: {},
  schemaFieldsToValidate: {},
  formDataToValidate: {},
  status: 0,
  validatorLoading: false,
  validatorErrors: [],
  facilityDetails: confSend.content.missingFacilityDetails
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

/**
 * In module "validator", extensive logging options are existent for debugging purposes on final submit.
 * These options have to be commented out for build process, as one does not want a polluted console log
 * in the deployed version of the application. See comments "debug error logging …".
 * */

const actions = {
  /**
   * Load each locations' module data in sequence for validation purposes.
   * Does not check requestType, company and send, as they are checked
   * in Card.vue/send-module on final submit.
   *
   * @return state.validatorErrors Array
   * */
  async loadModuleSchemaAndData(
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) {
    const userToken = rootState.auth.userToken;
    commit("set_validatorStatus", 1);
    commit("set_loading", true);
    for await (const el of payload) {
      const facilityToken = el.locationID ? el.locationID : null;
      const productionType = el.productionType.itemValue
        ? el.productionType.itemValue
        : null;
      const moduleName = productionTypeToStoreModule(productionType);
      const token = userToken + (facilityToken ? facilityToken : "");
      const schemaGetterName =
        moduleName + "/" + "get_" + moduleName + "Schema";
      const schema = rootGetters[schemaGetterName];
      // const formDataGetterName =
      //   moduleName + "/" + "get_" + moduleName + "Data";
      // const formdata = rootGetters[formDataGetterName];
      commit("set_schema", schema);

      if (el.editable || !el.hasOwnProperty("editable")) {
        commit("set_clearSchemaFieldsToValidate");
        await dispatch("getSchemaFieldsToValidator", state.schema, "required");
        /** debug error logging start */
        // console.log("%c" + el.facilityName + " will be processed now .........", "color: lightblue; font-weight: bold;");
        // if (el.editable) console.log(el.facilityName + " is editable: " + "%c" + el.editable, "color: yellow;");
        // if (!el.hasOwnProperty("editable")) console.log(el.facilityName + " has no property 'editable': " + "%c" + !el.hasOwnProperty("editable"), "color: yellow;");
        /** debug error logging end */
        try {
          const response = await apiRoutes.asyncGetModuleData(
            token,
            moduleName
          );
          if (response.data[0] === "SUCCESS") {
            let moduleData = response.data[2];
            let formdata = JSON.parse(moduleData);
            let schemaFieldsToValidateKeys = Object.keys(
              state.schemaFieldsToValidate
            );
            commit("set_formDataToValidate", formdata);
            /** debug error logging start */
            // console.log("%c" + el.facilityName + " response = SUCCESS", "color: green; font-weight: bold;");
            // console.log("keys to validate for " + el.facilityName + "\n" + "%c" + schemaFieldsToValidateKeys, "color: yellow;");
            // console.log(el.facilityName + " response has all needed data? " + "%c" + hasKeys(formdata, schemaFieldsToValidateKeys), "color: red; font-weight: bold;");
            /** debug error logging end */
            if (!hasKeys(formdata, schemaFieldsToValidateKeys)) {
              /** debug error logging start */
              // schemaFieldsToValidateKeys.forEach(function(x) {
              //   if (!hasKeys(formdata, [x])) {
              //     console.log("this field/key is missing -> " + "%c" + x, "color: red; font-weight: bold;");
              //   }
              // });
              // console.log("%c" + "error message for " + el.facilityName + " will be generated", "color: red; font-weight: bold;");
              /** debug error logging end */
              commit("set_error", [
                el.facilityName +
                  " => " +
                  productionTypeToErrorMessage(el.productionType.itemValue)
              ]);
            }
          } else if (response.data[1] === "no moduledata found") {
            /** debug error logging start */
            // console.log("%c" + el.facilityName + " response = no moduledata found", "color: yellow;");
            // console.log("%c" + "error message for " + el.facilityName + " will be generated", "color: red; font-weight: bold;");
            /** debug error logging end */
            commit("set_error", [
              el.facilityName +
                " => " +
                productionTypeToErrorMessage(el.productionType.itemValue)
            ]);
          }
        } catch (error) {
          console.log(error);
          commit("set_validatorStatus", 3);
          commit("set_loading", false);
        }
      } /** debug error logging start */ /*else if (el.editable === false) {
        console.log("%c" + el.facilityName + " is approved!", "color: green; font-weight: bold;");
      }*/ /** debug error logging end */
    }
    commit("set_validatorStatus", 2);
    commit("set_loading", false);
    return state.validatorErrors;
  },

  async getSchemaFieldsToValidator(
    { commit, dispatch },
    obj,
    name = "required"
  ) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (isObject(obj[key]) && obj.fieldType !== "DataTable") {
          await dispatch("getSchemaFieldsToValidator", obj[key], name);
        } else if (
          key === name &&
          obj[key] === true &&
          obj.validations.required
        ) {
          let res = {};
          res[obj.name] = obj;
          commit("set_schemaFieldsToValidate", res);
        }
      }
    }
  }
};

const mutations = {
  set_schema(state, payload) {
    Vue.set(state, "schema", payload);
  },

  set_formDataToValidate(state, payload) {
    Vue.set(state, "formDataToValidate", payload);
  },

  set_schemaFieldsToValidate(state, payload) {
    Object.assign(state.schemaFieldsToValidate, payload);
  },

  set_clearSchemaFieldsToValidate(state) {
    Vue.set(state, "schemaFieldsToValidate", {});
  },

  set_error(state, payload) {
    state.validatorErrors.push(...payload);
    state.validatorErrors = [...new Set(state.validatorErrors)];
  },

  set_validatorStatus(state, payload) {
    state.status = payload;
  },

  clear_ValidatorErrors(state) {
    state.validatorErrors.splice(0);
  },

  set_loading(state, payload) {
    state.validatorLoading = payload;
  }

  /*set_initialStateV(state) {
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  }*/
};

const getters = {
  get_ValidatorStatus: state => {
    return state.status;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
