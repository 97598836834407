<template>
  <v-footer
    id="core-footer"
    ref="coreFooter"
    app
    inset
    color="blue-grey darken-4"
    class="white--text justify-center"
  >
    <a
      :href="kat.url"
      :alt="$t(kat.altText)"
      style="text-decoration: none"
      target="_blank"
      class="white--text"
    >
      {{ kat.text }}
    </a>
    <a
      v-for="(link, i) in links"
      :key="i"
      :href="link.url"
      :alt="$t(link.altText)"
      style="text-decoration: none"
      target="_blank"
      class="white--text"
    >
      {{ $t(link.text) }}
    </a>
  </v-footer>
</template>

<script>
import confCore from "../../conf/confCore";
let year = new Date().getFullYear();
/* eslint-disable no-console */
export default {
  name: "CoreFooter",
  data() {
    return {
      kat: {
        text: `© ${year} – KAT e.V`,
        url: "https://www.was-steht-auf-dem-ei.de",
        altText: confCore.footerLink0
      },
      links: [
        // {
        //   text: `© ${year} – KAT e.V`,
        //   url: "https://www.was-steht-auf-dem-ei.de",
        //   altText: confCore.footerLink0
        // },
        {
          text: confCore.footerLink1,
          url: "https://www.was-steht-auf-dem-ei.de/de/kontakt/impressum.php",
          altText: confCore.footerLink1
        },
        {
          text: confCore.footerLink2,
          url: "https://www.was-steht-auf-dem-ei.de/de/datenschutz.php",
          altText: confCore.footerLink2
        }
      ]
    };
  }
};
</script>

<style scoped>
/*.v-footer--fixed {*/
/*  width: auto !important;*/
/*}*/

#core-footer a {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

@media only screen and (max-width: 411px) {
  #core-footer {
    font-size: 0.87rem;
    padding-left: 8px;
    padding-right: 8px;
  }

  #core-footer a {
    padding-right: 0.35rem;
    padding-left: 0.35rem;
  }
}
</style>
