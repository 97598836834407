import confDyehouse from "../../conf/confDyehouse";
import apiRoutes from "../../api/apiRoutes";
import Vue from "vue";
import apiCalls from "@/api/apiCalls";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    suppliers: [],
    recipients: [],
    files: [],
    dhRegistrationExistent: null,
    dhIfsExistent: null
  };
};

const state = () => ({
  moduleName: "dyehouse",
  formData: {
    suppliers: [],
    recipients: [],
    files: [],
    dhRegistrationExistent: null,
    dhIfsExistent: null
  },
  schema: { ...confDyehouse.schema },
  status: 0,
  editable: true,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateDyehouse: ({ commit }, payload) => {
    commit("setUpdateDyehouse", payload);
    commit("setHideDyehouse");
  },

  /**
   * Add or update dyehouse's module data.
   * Actual saving functionality.
   * */
  addDyehouse: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load dyehouse's module data.
   * Done in dyehouse's view created hook.
   * */
  loadDyehouse: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFileDyehouse: ({ state, rootState, rootGetters }, payload) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    if (payload[0].fieldName === "dhRegistrationFile") {
      payload[0].fileListName = "dhRegistrationFileList";
    } else if (payload[0].fieldName === "dhIfsFile") {
      payload[0].fileListName = "dhIfsFileList";
    }
    let existFiles = [...state.formData.files];
    let addFiles = payload;
    addFiles = addFiles.map(
      ({ fieldName, stableID, fileName, uID, fileListName }) => ({
        fieldName,
        stableID,
        fileName,
        uID,
        fileListName
      })
    );
    addFiles.forEach(item => {
      existFiles.push(item);
    });
    let data = {
      token: token,
      moduleName: state.moduleName,
      payload: payload,
      existFiles: existFiles
    };
    apiCalls.addFile(data);
  },

  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFileDyehouse: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusDyehouse", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusDyehouse", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          let files = [...state.formData.files];
          files.splice(
            files.findIndex(function(i) {
              return i.uID === payload.uID;
            }),
            1
          );
          commit("setFileDyehouse", files);
          if (
            payload.fileListName === "dhRegistrationFileList" &&
            state.formData.files.filter(
              files => files.fileListName === payload.fileListName
            ).length === 0 &&
            state.formData.dhRegistrationExistent === true
          ) {
            commit("setRegistrationExistentDyehouse", null);
            commit("setRegistrationFileListDyehouse");
          } else if (
            payload.fileListName === "dhIfsFileList" &&
            state.formData.files.filter(
              files => files.fileListName === payload.fileListName
            ).length === 0 &&
            state.formData.dhIfsExistent === true
          ) {
            commit("setIfsExistentDyehouse", null);
            commit("setIfsFileListDyehouse");
          }
          dispatch("addDyehouse");
          commit("setStatusDyehouse", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusDyehouse", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  }
};

const mutations = {
  /**
   * If loadDyehouse returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataDyehouse(state) {
    // Vue.set(state, "formData", initialFormData);
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confDyehouse.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateDyehouse(state, payload) {
    state.formData = payload;
  },

  /**
   * Set dhRegistrationExistent
   * */
  setRegistrationExistentDyehouse(state, payload) {
    state.formData.dhRegistrationExistent = payload;
  },

  /**
   * Set deletion of dhRegistrationFileList
   * */
  setRegistrationFileListDyehouse(state) {
    Vue.delete(state.formData, "dhRegistrationFileList");
  },

  /**
   * Set dhIfsExistent
   * */
  setIfsExistentDyehouse(state, payload) {
    state.formData.dhIfsExistent = payload;
  },

  /**
   * Set deletion of dhIfsFileList
   */
  setIfsFileListDyehouse(state) {
    Vue.delete(state.formData, "dhIfsFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideDyehouse(state) {
    if (state.formData.dhRegistrationExistent === true) {
      state.schema.dhRegistrationFile.hidden = false;
      state.schema.dhRegistrationFileLater.hidden = true;
      Vue.delete(state.formData, "dhRegistrationFileLater");
    } else if (state.formData.dhRegistrationExistent === false) {
      state.schema.dhRegistrationFile.hidden = true;
      state.schema.dhRegistrationFileLater.hidden = false;
      if (
        !!state.formData.dhRegistrationFileList &&
        state.formData.dhRegistrationFileList.length < 1
      ) {
        Vue.delete(state.formData, "dhRegistrationFileList");
      }
    }
    if (state.formData.dhRegistrationExistent === null) {
      state.schema.dhRegistrationFile.hidden = true;
      state.schema.dhRegistrationFileLater.hidden = true;
    }
    if (state.formData.dhIfsExistent === true) {
      state.schema.dhIfsFile.hidden = false;
      state.schema.dhIfsFileLater.hidden = true;
      Vue.delete(state.formData, "dhIfsFileLater");
    } else if (state.formData.dhIfsExistent === false) {
      state.schema.dhIfsFile.hidden = true;
      state.schema.dhIfsFileLater.hidden = false;
      if (
        !!state.formData.dhIfsFileList &&
        state.formData.dhIfsFileList.length < 1
      ) {
        Vue.delete(state.formData, "dhIfsFileList");
      }
    }
    if (state.formData.dhIfsExistent === null) {
      state.schema.dhIfsFile.hidden = true;
      state.schema.dhIfsFileLater.hidden = true;
    }
    if (state.formData.dhProductionSiteChoose === true) {
      state.schema.dhInformation.hidden = true;
      [
        "dhPlantManager",
        "dhPmFirstName",
        "dhPmLastName",
        "dhPmPhone",
        "dhPmMobile",
        "dhPmFax",
        "dhPmEmail",
        "dhStreetNumber",
        "dhPostalCode",
        "dhLocation",
        "dhCountry",
        "dhRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.dhProductionSiteChoose === false) {
      state.schema.dhInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusDyehouse(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setDyehouse(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for file-objects.
   * */
  setFileDyehouse(state, payload) {
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for company data due to approval.
   * */
  setEditableDyehouse(state, payload) {
    state.editable = payload;
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => Dyehouse.vue
   * */
  getDataDyehouse: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => Dyehouse.vue
   * */
  getSchemaDyehouse: state => {
    return state.schema;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList. Injected via conf….json-file
   * as a prop. Despite the other getters of the same type,
   * this one is used when several FileSelectList-components
   * are available on the same level. Distinguishes between
   * the different lists with their names, which are set in
   * the conf….json-files.
   * */
  getFilesByFileListNameDyehouse(state) {
    return fileListName =>
      state.formData.files.filter(files => {
        if (files.fileListName === fileListName) {
          return files;
        } else return null;
      });
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getRegistrationExistentDyehouse(state) {
    return state.formData.dhRegistrationExistent;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getIfsExistentDyehouse(state) {
    return state.formData.dhIfsExistent;
  },

  /**
   * Get editability of company data (aside locations array)
   * */
  getEditableDyehouse: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
