import confRequestType from "../../conf/confRequestType.json";
import apiRoutes from "../../api/apiRoutes";
import router from "../../router/index";
import Vue from "vue";

/* eslint-disable no-console */
const initialState = () => {
  return {
    moduleName: "requestType",
    formData: {},
    schema: { ...confRequestType.schema },
    editable: true,
    status: null,
    error: null
  };
};

const state = () => ({
  moduleName: "requestType",
  formData: {},
  schema: { ...confRequestType.schema },
  editable: true,
  status: null,
  error: null
});

// const state = initialState();

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateRequestType: ({ commit }, payload) => {
    commit("setUpdateRequestType", payload);
    commit("setHideRequestType");
  },

  /**
   * Add or update requestType's module data.
   * Actual saving functionality.
   * */
  addRequestType({ commit, state, rootState, dispatch }) {
    const token = rootState.auth.userToken;
    commit("setStatusRequestType", 1);
    apiRoutes
      .postAddModuleData(token, state.moduleName, state.formData)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusRequestType", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrorsave" },
            { root: true }
          );
        } else {
          dispatch("loadRequestType");
          commit("setStatusRequestType", 2);
          router.push({ name: "company" });
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusRequestType", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Load requestType's module data.
   * Done in requestType's view created hook.
   * */
  loadRequestType: ({ commit, state, rootState }) => {
    return new Promise((resolve, reject) => {
      const token = rootState.auth.userToken;
      commit("setStatusRequestType", 1);
      apiRoutes
        .getModuleData(token, state.moduleName)
        .then(response => {
          if (response.data[0] === "ERROR") {
            if (response.data[1] === "no moduledata found") {
              commit("setInitialStateRequestType");
              commit("setStatusRequestType", 2);
            } else commit("setStatusRequestType", 3);
            resolve(3);
          } else {
            let moduleData = JSON.parse(response.data[2]);
            commit("setEditableRequestType", response.data[3] === "editable");
            commit("setRequestType", moduleData);
            commit("setHideRequestType");
            commit("setStatusRequestType", 2);
            resolve(2);
          }
        })
        .catch(e => {
          console.log(e);
          commit("setStatusRequestType", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.networkerror" },
            { root: true }
          );
          reject(e);
        });
    });
  },

  /**
   * Get requestType moduleData as boolean status for validation purpose in send-module.
   * Used in Card.vue with custom validation instead of json-driven
   * approach using vuelidate.
   *
   * @return boolean true|false
   * */
  async loadRequestTypeModuleData({ state, rootState }) {
    const token = rootState.auth.userToken;

    try {
      const response = await apiRoutes.getModuleData(token, state.moduleName);
      return !(
        response.data[0] === "ERROR" &&
        response.data[1] === "no moduledata found"
      );
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * Done on company/user-logout in auth-module.
   * */
  clearRequestTypeState: ({ commit }) => {
    commit("setInitialStateRequestType");
  }
};

const mutations = {
  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateRequestType(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideRequestType(state) {
    if (state.formData.rtApplicationtypeNew === true) {
      state.schema.rtSpecialNumber.hidden = true;
      Vue.delete(state.formData, "rtSpecialNumber");
    } else if (state.formData.rtApplicationtypeNew === false) {
      state.schema.rtSpecialNumber.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusRequestType(state, payload) {
    state.status = payload;
  },

  /**
   * Setter to change formData-object
   * upon any api-call.
   * */
  setRequestType(state, payload) {
    state.formData = payload;
  },

  /**
   * Set editability for data due to approval.
   * */
  setEditableRequestType(state, payload) {
    state.editable = payload;
  },

  setInitialStateRequestType(state) {
    /*const s = initialFormDataFunc();
    Object.keys(s).forEach(key => {
      console.log("key: " + key + " will be reset now to: " + JSON.stringify(s[key]));
      state[key] = s[key];
    });*/
    Object.assign(state, initialState());
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => RequestType.vue
   * */
  getDataRequestType: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => RequestType.vue
   * */
  getSchemaRequestType: state => {
    return state.schema;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditableRequestType: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
