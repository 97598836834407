import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible || (_vm.routesData.children && _vm.routesData.children.length))?_c(VList,{attrs:{"nav":"","dense":_vm.dense}},[(_vm.isVisible)?_c(VListItem,{attrs:{"ripple":"","active-class":"primary","to":{ name: _vm.routesData.name }}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.routesData.meta.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(_vm.routesData.meta.title)))])],1)],1):_vm._e(),(
      _vm.facilities.length > 0 &&
        _vm.routesData.children &&
        _vm.routesData.children.length
    )?[_vm._l((_vm.facilities),function(facility,uid){return _c(VList,{key:uid,attrs:{"nav":""}},[(_vm.facilities && _vm.routesData.children && _vm.routesData.children.length)?_c(VListItem,{key:uid,attrs:{"active-class":"primary","to":facility.path},on:{"click":function($event){return _vm.addSingleLocationID(facility.locationID)}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(facility.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(facility.name))])],1)],1):_vm._e()],1)}),_c(VDivider)]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }