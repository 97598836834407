import confPreraiser from "../../conf/confPreraiser.json";
import apiRoutes from "../../api/apiRoutes";
import apiCalls from "@/api/apiCalls";
import Vue from "vue";
import { hashCode } from "@/helpers/helpers";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    preStables: [],
    suppliers: [],
    recipients: [],
    files: [],
    preRegulatoryApprovalExistent: null
  };
};

//const initialFormDataFunc = () => initialFormData;

const state = () => ({
  moduleName: "preraiser",
  formData: {
    preStables: [],
    suppliers: [],
    recipients: [],
    files: [],
    preRegulatoryApprovalExistent: null
  },
  schema: { ...confPreraiser.schema },
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * Sets uID for each stable.
   * */
  updatePreraiser: ({ commit }, payload) => {
    if (payload.preStables) {
      payload.preStables.forEach(function(item) {
        if (!Object.prototype.hasOwnProperty.call(item, "uID")) {
          item.stableID = hashCode(Date.now() + Math.random().toString());
        }
      });
    }
    commit("setUpdatePreraiser", payload);
    commit("setHidePreraiser");
  },

  /**
   * Add or update preraiser's module data.
   * Actual saving functionality.
   * */
  addPreraiser: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load preraiser's module data.
   * Done in preraiser's view created hook.
   * */
  loadPreraiser: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  },

  /**
   * Get preraiser moduleData for validation purpose in send-module.
   * Used in Card.vue with custom validation instead of json-driven
   * approach using vuelidate.
   * */
  async loadPreModuleData({ commit, state, rootState }, payload) {
    const userToken = rootState.auth.userToken;
    const token = userToken + payload;
    commit("setStatusPreraiser", 1);

    try {
      const response = await apiRoutes.getModuleData(token, state.moduleName);
      if (response.data[0] === "SUCCESS") {
        let moduleData = response.data[2];
        commit("setStatusPreraiser", 2);
        return JSON.parse(moduleData);
      } else return { preStables: [] }; // if no module-data exist return empty stable-array in order to generate UI error
    } catch (error) {
      console.log(error);
      commit("setStatusPreraiser", 3);
    }
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFilePreraiser: ({ state, rootState, rootGetters }, payload) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let existFiles = [...state.formData.files];
    let addFiles = payload;
    addFiles = addFiles.map(({ fieldName, stableID, fileName, uID }) => ({
      fieldName,
      stableID,
      fileName,
      uID
    }));
    addFiles.forEach(item => {
      existFiles.push(item);
    });
    let data = {
      token: token,
      moduleName: state.moduleName,
      payload: payload,
      existFiles: existFiles
    };
    apiCalls.addFile(data);
  },

  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFilePreraiser: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusPreraiser", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusPreraiser", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          let files = [...state.formData.files];
          files.splice(
            files.findIndex(function(i) {
              return i.uID === payload.uID;
            }),
            1
          );
          commit("setFilePreraiser", files);
          if (
            state.formData.files.length === 0 &&
            state.formData.preRegulatoryApprovalExistent === true
          ) {
            commit("setRegulatoryApprovalExistentPreraiser", null);
            commit("setRegulatoryApprovalFileListPreraiser");
          }
          dispatch("addPreraiser");
          commit("setStatusPreraiser", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusPreraiser", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  }
};

const mutations = {
  /**
   * If loadPreraiser returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataPreraiser(state) {
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confPreraiser.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdatePreraiser(state, payload) {
    state.formData = payload;
  },

  /**
   * Set preRegulatoryApprovalExistent
   * */
  setRegulatoryApprovalExistentPreraiser(state, payload) {
    state.formData.preRegulatoryApprovalExistent = payload;
  },

  /**
   * Sets deletion of preRegulatoryApprovalFileList
   * */
  setRegulatoryApprovalFileListPreraiser(state) {
    Vue.delete(state.formData, "preRegulatoryApprovalFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHidePreraiser(state) {
    if (state.formData.preRegulatoryApprovalExistent === true) {
      state.schema.preRegulatoryApprovalFile.hidden = false;
      state.schema.preRegulatoryApprovalLater.hidden = true;
      Vue.delete(state.formData, "preRegulatoryApprovalLater");
    } else if (state.formData.preRegulatoryApprovalExistent === false) {
      state.schema.preRegulatoryApprovalFile.hidden = true;
      state.schema.preRegulatoryApprovalLater.hidden = false;
      if (
        !!state.formData.preRegulatoryApprovalFileList &&
        state.formData.preRegulatoryApprovalFileList.length < 1
      ) {
        Vue.delete(state.formData, "preRegulatoryApprovalFileList");
      }
    }
    if (state.formData.preProductionSiteChoose === true) {
      state.schema.preInformation.hidden = true;
      [
        "prePlantManager",
        "prePmFirstName",
        "prePmLastName",
        "prePmPhone",
        "prePmMobile",
        "prePmFax",
        "prePmEmail",
        "preStreetNumber",
        "prePostalCode",
        "preLocation",
        "preCountry",
        "preRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.preProductionSiteChoose === false) {
      state.schema.preInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusPreraiser(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setPreraiser(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for file-objects.
   * */
  setFilePreraiser(state, payload) {
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for data due to approval.
   * */
  setEditablePreraiser(state, payload) {
    state.editable = payload;
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => Preraiser.vue
   * */
  getDataPreraiser: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => Preraiser.vue
   * */
  getSchemaPreraiser: state => {
    return state.schema;
  },

  /**
   * Ignore the unused property-hint!
   * Used in FileSelectList. Injected via
   * Injected via conf….json-file as a prop.
   * */
  getFilesPreraiser: state => {
    return state.formData.files;
  },

  /**
   * Ignore the unused property-hint!
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getRegulatoryApprovalExistentPreraiser(state) {
    return state.formData.preRegulatoryApprovalExistent;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditablePreraiser: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
