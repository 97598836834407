import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"id":"core-appbar","app":"","clipped-left":"","dark":"","color":"blue-grey darken-4"}},[(_vm.isAuthenticated)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle-drawer')}}}):_vm._e(),_c(VImg,{staticClass:"shrink ml-2 mr-2",attrs:{"alt":"KAT e.V. Logo","contain":"","src":require("../../assets/kat-logo.png"),"transition.scale":"","width":"30px"}}),_c(VToolbarTitle,{style:({ 'font-size': _vm.fontSize })},[_vm._v(" "+_vm._s(_vm.$t(_vm.appbarTitle))+" ")]),_c(VSpacer),_c(VSpeedDial,{attrs:{"transition":"scale-transition","direction":"bottom"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(data){return [_c(VBtn,_vm._g({staticClass:"mx-2",attrs:{"fab":"","elevation":"4","dark":"","ripple":"","x-small":"","color":"primary"},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},data.on),[(_vm.fab)?_c(VIcon,[_vm._v("$mdiClose")]):_c(VIcon,[_vm._v("$mdiTranslate")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.appbarLanguageBtn)))])])]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c(VBtn,{attrs:{"fab":"","elevation":"4","dark":"","ripple":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.switchLocale('de')}}},[_c('span',[_vm._v("DE")])]),_c(VBtn,{attrs:{"fab":"","elevation":"4","dark":"","ripple":"","x-small":"","color":"indigo"},on:{"click":function($event){return _vm.switchLocale('nl')}}},[_c('span',[_vm._v("NL")])]),_c(VBtn,{attrs:{"fab":"","elevation":"4","dark":"","ripple":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.switchLocale('en')}}},[_c('span',[_vm._v("EN")])])],1),(_vm.isAuthenticated)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","elevation":"4","dark":"","ripple":"","x-small":"","color":"primary"},on:{"click":_vm.logout}},on),[_c(VIcon,[_vm._v("$mdiLogout")])],1)]}}],null,false,1807513749)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.appbarLogoutBtn)))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }