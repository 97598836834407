<template>
  <v-list
    nav
    :dense="dense"
    v-if="isVisible || (routesData.children && routesData.children.length)"
  >
    <v-list-item
      v-if="isVisible"
      ripple
      active-class="primary"
      :to="{ name: routesData.name }"
    >
      <!-- force reload site: isn't needed due to the check if moduledata -->
      <!-- is available or not and formData is freshly initialized    =>    :href="$router.resolve({ name: routesData.name }).href" -->
      <!-- history mode: preserves state                              =>    :to="{ name: routesData.name }" -->
      <v-list-item-icon class="mr-2">
        <v-icon style="font-size: 24px">{{ routesData.meta.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t(routesData.meta.title) }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <template
      v-if="
        facilities.length > 0 &&
          routesData.children &&
          routesData.children.length
      "
    >
      <v-list v-for="(facility, uid) in facilities" :key="uid" nav>
        <v-list-item
          v-if="facilities && routesData.children && routesData.children.length"
          :key="uid"
          active-class="primary"
          :to="facility.path"
          @click="addSingleLocationID(facility.locationID)"
        >
          <v-list-item-icon class="mr-2">
            <v-icon style="font-size: 24px">{{ facility.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ facility.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
    </template>
  </v-list>
</template>

<script>
import { mapActions } from "vuex";
import { facilitiesToRoutes } from "@/helpers/routeMapper";

/* eslint-disable no-console */
export default {
  name: "CoreNavigationItem",

  props: {
    routesData: Object
  },
  computed: {
    dense() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    /**
     * check if the route can be displayed or not
     */
    isVisible() {
      if (this.$store.getters["auth/isAuthenticated"]) {
        return (
          (this.routesData.meta.permission === "company" &&
            this.$store.getters["auth/isAuthenticatedCompanyLevel"]) ||
          this.routesData.meta.permission === "user"
        );
      } else return false;
    },
    /**
     * Builds navigation in Sidebar.
     * Facilities are mapped to routes by helper fn.
     * */
    facilities() {
      let facilities = [];
      if (this.$store.getters["auth/isAuthenticatedCompanyLevel"]) {
        this.$store.getters["company/get_locations"].forEach(function(
          facility
        ) {
          if (facility.productionType !== undefined) {
            facilities.push(facilitiesToRoutes(facility));
          }
        });
      }
      return facilities;
    }
  },
  methods: {
    ...mapActions({ addSingleLocationID: "company/addSingleLocationID" })
  }
};
</script>

<style scoped></style>
