import confPacking from "../../conf/confPacking";
import apiRoutes from "../../api/apiRoutes";
import Vue from "vue";
import apiCalls from "@/api/apiCalls";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    suppliers: [],
    recipients: [],
    files: [],
    psRegistrationExistent: null,
    psIfsExistent: null
  };
};

const state = () => ({
  moduleName: "packing",
  formData: {
    suppliers: [],
    recipients: [],
    files: [],
    psRegistrationExistent: null,
    psIfsExistent: null
  },
  schema: { ...confPacking.schema },
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updatePacking: ({ commit, dispatch }, payload) => {
    commit("setUpdatePacking", payload);
    commit("setHidePacking");
    dispatch("checkIsAustria");
  },

  /**
   * Add or update packing's module data.
   * Actual saving functionality.
   * */
  addPacking: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load packing's module data.
   * Done in packing's view created hook.
   * */
  loadPacking: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFilePacking: ({ state, rootState, rootGetters }, payload) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    if (payload[0].fieldName === "psRegistrationFile") {
      payload[0].fileListName = "psRegistrationFileList";
    } else if (payload[0].fieldName === "psIfsFile") {
      payload[0].fileListName = "psIfsFileList";
    }
    let existFiles = [...state.formData.files];
    let addFiles = payload;
    addFiles = addFiles.map(
      ({ fieldName, stableID, fileName, uID, fileListName }) => ({
        fieldName,
        stableID,
        fileName,
        uID,
        fileListName
      })
    );
    addFiles.forEach(item => {
      existFiles.push(item);
    });
    let data = {
      token: token,
      moduleName: state.moduleName,
      payload: payload,
      existFiles: existFiles
    };
    apiCalls.addFile(data);
  },

  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFilePacking: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusPacking", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusPacking", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          let files = [...state.formData.files];
          files.splice(
            files.findIndex(function(i) {
              return i.uID === payload.uID;
            }),
            1
          );
          commit("setFilePacking", files);
          if (
            payload.fileListName === "psRegistrationFileList" &&
            state.formData.files.filter(
              files => files.fileListName === payload.fileListName
            ).length === 0 &&
            state.formData.psRegistrationExistent === true
          ) {
            commit("setRegistrationExistentPacking", null);
            commit("setRegistrationFileListPacking");
          } else if (
            payload.fileListName === "psIfsFileList" &&
            state.formData.files.filter(
              files => files.fileListName === payload.fileListName
            ).length === 0 &&
            state.formData.psIfsExistent === true
          ) {
            commit("setIfsExistentPacking", null);
            commit("setIfsFileListPacking");
          }
          dispatch("addPacking");
          commit("setStatusPacking", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusPacking", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Check if packing-station or company is set in Austria.
   * @param commit
   * @param state
   * @param rootGetters
   */
  checkIsAustria: ({ commit, state, rootGetters }) => {
    let ownAddress = state.formData.psProductionSiteChoose === false;
    let companyIsAustria = rootGetters["company/get_isAustria"];
    let facilityIsAustria =
      typeof state.formData.psCountry !== "undefined" &&
      state.formData.psCountry.itemValue === "GlobalCountry_2";
    let payload = {
      ownAddress: ownAddress,
      companyIsAustria: companyIsAustria,
      facilityIsAustria: facilityIsAustria
    };
    commit("setAMACertificationPacking", payload);
  }
};

const mutations = {
  /**
   * If loadPacking returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataPacking(state) {
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confPacking.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdatePacking(state, payload) {
    state.formData = payload;
  },

  /**
   * Set psRegistrationExistent
   * */
  setRegistrationExistentPacking(state, payload) {
    state.formData.psRegistrationExistent = payload;
  },

  /**
   * Sets deletion of psRegistrationFileList
   * */
  setRegistrationFileListPacking(state) {
    Vue.delete(state.formData, "psRegistrationFileList");
  },

  /**
   * Set psIfsExistent
   * */
  setIfsExistentPacking(state, payload) {
    state.formData.psIfsExistent = payload;
  },

  /**
   * Sets deletion of psIfsFileList
   * */
  setIfsFileListPacking(state) {
    Vue.delete(state.formData, "psIfsFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHidePacking(state) {
    if (state.formData.psRegistrationExistent === true) {
      state.schema.psRegistrationFile.hidden = false;
      state.schema.psRegistrationFileLater.hidden = true;
      Vue.delete(state.formData, "psRegistrationFileLater");
    } else if (state.formData.psRegistrationExistent === false) {
      state.schema.psRegistrationFile.hidden = true;
      state.schema.psRegistrationFileLater.hidden = false;
      if (
        !!state.formData.psRegistrationFileList &&
        state.formData.psRegistrationFileList.length < 1
      ) {
        Vue.delete(state.formData, "psRegistrationFileList");
      }
    }
    if (state.formData.psRegistrationExistent === null) {
      state.schema.psRegistrationFile.hidden = true;
      state.schema.psRegistrationFileLater.hidden = true;
    }
    if (state.formData.psIfsExistent === true) {
      state.schema.psIfsFile.hidden = false;
      state.schema.psIfsFileLater.hidden = true;
      Vue.delete(state.formData, "psIfsFileLater");
    } else if (state.formData.psIfsExistent === false) {
      state.schema.psIfsFile.hidden = true;
      state.schema.psIfsFileLater.hidden = false;
      if (
        !!state.formData.psIfsFileList &&
        state.formData.psIfsFileList.length < 1
      ) {
        Vue.delete(state.formData, "psIfsFileList");
      }
    }
    if (state.formData.psIfsExistent === null) {
      state.schema.psIfsFile.hidden = true;
      state.schema.psIfsFileLater.hidden = true;
    }
    if (state.formData.psProductionSiteChoose === true) {
      state.schema.psInformation.hidden = true;
      [
        "psPlantManager",
        "psPmFirstName",
        "psPmLastName",
        "psPmPhone",
        "psPmMobile",
        "psPmFax",
        "psPmEmail",
        "psStreetNumber",
        "psPostalCode",
        "psLocation",
        "psCountry",
        "psRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.psProductionSiteChoose === false) {
      state.schema.psInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusPacking(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setPacking(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for file-objects.
   * */
  setFilePacking(state, payload) {
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for data due to approval.
   * */
  setEditablePacking(state, payload) {
    state.editable = payload;
  },

  /**
   * Set radio "AMA-Certification" for austrian packing-facilities/companies.
   *
   * @param {Object} state
   * @param {Object} payload
   */
  setAMACertificationPacking(state, payload) {
    if (
      (payload.companyIsAustria && !payload.ownAddress) ||
      (payload.ownAddress && payload.facilityIsAustria)
    ) {
      if (
        !state.schema.psAMACertificationExistent.hasOwnProperty("validations")
      ) {
        let obj = {
          required: {
            params: null,
            message: "validationMessages.textRequired"
          }
        };
        Vue.set(state.schema.psAMACertificationExistent, "validations", obj);
      }
      state.schema.psAMACertificationExistent.hidden = false;
      state.schema.psAMACertificationExistent.required = true;
      state.schema.psAMACertificationExistent.toValidate = true;
    } else {
      state.schema.psAMACertificationExistent.hidden = true;
      state.schema.psAMACertificationExistent.required = false;
      state.schema.psAMACertificationExistent.toValidate = false;
      Vue.delete(state.formData, "psAMACertificationExistent");
      Vue.delete(state.schema.psAMACertificationExistent, "validations");
    }
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => Packing.vue
   * */
  getDataPacking: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => Packing.vue
   * */
  getSchemaPacking: state => {
    return state.schema;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList. Injected via conf….json-file
   * as a prop. Despite the other getters of the same type,
   * this one is used when several FileSelectList-components
   * are available on the same level. Distinguishes between
   * the different lists with their names, which are set in
   * the conf….json-files.
   * */
  getFilesByFileListNamePacking(state) {
    return fileListName =>
      state.formData.files.filter(files => {
        if (files.fileListName === fileListName) {
          return files;
        } else return null;
      });
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getRegistrationExistentPacking(state) {
    return state.formData.psRegistrationExistent;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getIfsExistentPacking(state) {
    return state.formData.psIfsExistent;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditablePacking: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
