<template>
  <v-navigation-drawer
    id="core-drawer"
    ref="coreDrawer"
    v-model="drawer"
    app
    dark
    clipped
    :width="drawerWidth"
  >
    <core-navigation-item
      v-for="(route, index) in computedRoutes"
      :key="index"
      :routesData="route"
    />
  </v-navigation-drawer>
</template>

<script>
/* eslint-disable no-console */
import CoreNavigationItem from "./CoreNavigationItem";

export default {
  components: { CoreNavigationItem },
  data() {
    return {
      clipped: true,
      drawer: true // drawer is always visible on desktop, not on mobile. Breakpoint is 1024 px. False = permanently hidden
    };
  },
  computed: {
    drawerWidth() {
      if (this.$i18n.locale === "de" && !this.$vuetify.breakpoint.smAndDown) {
        return 292;
      } else if (
        (this.$i18n.locale === "en" || this.$i18n.locale === "nl") &&
        this.$vuetify.breakpoint.smAndDown
      ) {
        return 190;
      } else return 256;
    },

    computedRoutes() {
      return this.$router.options.routes;
    }
  }
};
</script>

<style scoped>
#core-drawer {
  background: linear-gradient(rgba(38, 50, 56, 0.9), rgba(38, 50, 56, 0.9)),
    url("../../assets/reg_bg_fhd.jpg") fixed center center;
  background-size: cover;
  max-height: 100vh;
  padding: 0;
  margin: 0;
}
</style>
