<template>
  <v-app-bar id="core-appbar" app clipped-left dark color="blue-grey darken-4">
    <v-app-bar-nav-icon
      v-if="isAuthenticated"
      @click.stop="$emit('toggle-drawer')"
    />
    <v-img
      alt="KAT e.V. Logo"
      class="shrink ml-2 mr-2"
      contain
      src="../../assets/kat-logo.png"
      transition.scale
      width="30px"
    >
    </v-img>
    <v-toolbar-title :style="{ 'font-size': fontSize }">
      {{ $t(appbarTitle) }}
    </v-toolbar-title>
    <v-spacer />
    <v-speed-dial
      v-model="fab"
      transition="scale-transition"
      direction="bottom"
    >
      <template v-slot:activator>
        <v-tooltip left>
          <template #activator="data">
            <v-btn
              v-model="fab"
              fab
              elevation="4"
              dark
              ripple
              x-small
              color="primary"
              class="mx-2"
              v-on="data.on"
            >
              <v-icon v-if="fab">$mdiClose</v-icon>
              <v-icon v-else>$mdiTranslate</v-icon>
            </v-btn>
          </template>
          <span>{{ $t(appbarLanguageBtn) }}</span>
        </v-tooltip>
      </template>
      <v-btn
        fab
        elevation="4"
        dark
        ripple
        x-small
        color="green"
        @click="switchLocale('de')"
      >
        <span>DE</span>
      </v-btn>
      <v-btn
        fab
        elevation="4"
        dark
        ripple
        x-small
        color="indigo"
        @click="switchLocale('nl')"
      >
        <span>NL</span>
      </v-btn>
      <v-btn
        fab
        elevation="4"
        dark
        ripple
        x-small
        color="red"
        @click="switchLocale('en')"
      >
        <span>EN</span>
      </v-btn>
    </v-speed-dial>
    <v-tooltip v-if="isAuthenticated" left>
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          elevation="4"
          dark
          ripple
          x-small
          color="primary"
          v-on="on"
          @click="logout"
        >
          <v-icon>$mdiLogout</v-icon>
        </v-btn>
      </template>
      <span>{{ $t(appbarLogoutBtn) }}</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
/* eslint-disable no-console */
import confCore from "../../conf/confCore";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CoreAppbar",

  data() {
    return {
      drawer: false,
      appbarTitle: confCore.appbarTitle,
      appbarLogoutBtn: confCore.appbarLogoutBtn,
      appbarLanguageBtn: confCore.appbarLanguageBtn,
      fab: false
    };
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),

    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0.8rem";
        default:
          return "1.25rem";
      }
    }
  },

  methods: {
    ...mapActions("auth", ["logout", "changeLocale"]),

    logout: function() {
      this.$store.dispatch("auth/logout");
      location.reload();
    },

    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$root.$i18n.locale = locale;
        this.changeLocale(locale);
      }
    }
  }
};
</script>

<style scoped></style>
