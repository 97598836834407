import confCompany from "../../conf/confCompany.json";
import apiRoutes from "../../api/apiRoutes";
import { hashCode, isNotEmptyArray } from "../../helpers/helpers";
import Vue from "vue";
import apiCalls from "@/api/apiCalls";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    locations: []
  };
};

const state = () => ({
  moduleName: "company",
  formData: {
    locations: []
  },
  schema: { ...confCompany.schema },
  productionTypes: [],
  locationID: localStorage.getItem("locationID"),
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  /* eslint-disable no-unused-vars */
  updateCompany: ({ commit }, payload) => {
    return new Promise(resolve => {
      if (payload.locations) {
        payload.locations.forEach(function(item) {
          if (!Object.prototype.hasOwnProperty.call(item, "uID")) {
            item.uID = hashCode(Date.now() + Math.random().toString());
          }
        });
      }
      commit("set_updateCompany", payload);
      commit("set_hideCompany");
      resolve();
    });
  },
  /* eslint-disable no-unused-vars */

  /**
   * Adds a facility.
   * Returns the facilityToken aka. locationID.
   * The facility is saved in its own DB-table and
   * independent from company's module data.
   * This set of data is not deletable by the user.
   * */
  addFacility({ commit, dispatch, rootState }, payload) {
    const token = rootState.auth.userToken;
    commit("set_status", 1);
    apiRoutes
      .postAddFacility(
        token,
        payload.productionType.itemValue,
        payload.facilityName
      )
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrorsave" },
            { root: true }
          );
        } else {
          const locationID = response.data[1]; //facilityToken
          const uID = payload.uID;
          commit("set_locationID", { locationID, uID });
          dispatch("addCompany");
          commit("set_status", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("set_status", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Delete facility and all related data,
   * e.G. sub-modules and files. Company's
   * location-array is altered also.
   * payload is the locationID aka. facilityToken
   * */
  deleteFacility({ commit, dispatch, rootState }, payload) {
    const token = rootState.auth.userToken + payload;
    commit("set_status", 1);
    apiRoutes
      .getDeleteFacility(token)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          dispatch("addCompany");
          commit("set_status", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("set_status", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Add or update company's module data.
   * Actual saving functionality.
   * */
  addCompany({ commit, dispatch, state, rootState }) {
    const token = rootState.auth.userToken;
    commit("set_status", 1);
    apiRoutes
      .postAddModuleData(token, state.moduleName, state.formData)
      .then(response => {
        if (
          response.data[0] === "ERROR" &&
          response.data[1] !== "data not editable"
        ) {
          commit("set_status", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrorsave" },
            { root: true }
          );
        } else {
          dispatch("loadCompany");
          commit("set_status", 2);
          commit("global_successStatus", true, { root: true });
          commit("global_success", true, { root: true });
          setTimeout(() => {
            commit("global_clearSuccess", null, { root: true });
          }, 4000);
        }
      })
      .catch(error => {
        console.log(error);
        commit("set_status", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Load company's module data.
   * Done in company's view created hook.
   * */
  loadCompany: ({ commit, dispatch, state, rootState }) => {
    const token = rootState.auth.userToken;
    let silentSave = false;
    commit("set_status", 1);
    dispatch("loadGlobalLists", null, { root: true });
    apiRoutes
      .getModuleData(token, state.moduleName)
      .then(response => {
        if (response.data[0] === "ERROR") {
          if (response.data[1] === "no moduledata found") {
            // commit("set_initialFormDataC");
            commit("set_editableCompany", true);
            commit("set_status", 2);
          } else commit("set_status", 3);
        } else {
          let moduleData = JSON.parse(response.data[2]);
          let res = response.data[4].map(obj => {
            return {
              locationID: Object.keys(obj)[0],
              editable: Object.values(obj)[0] === "editable"
            };
          });
          moduleData.locations.forEach(item =>
            Object.assign(
              item,
              res.find(x => x.locationID === item.locationID)
            )
          );
          if (moduleData["baCompanyAddress"]) {
            delete Object.assign(moduleData, {
              ["baStreetNumber"]: moduleData["baCompanyAddress"]
            })["baCompanyAddress"];
            silentSave = true;
          }
          commit("set_editableCompany", response.data[3] === "editable");
          commit("set_company", moduleData);
          commit("set_hideCompany");
          commit("set_status", 2);
          if (silentSave) {
            dispatch("silentSaveModuleData");
          }
        }
        dispatch("loadProductionTypesList"); // this list is only needed in company module
      })
      .catch(error => {
        console.log(error);
        commit("set_status", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  silentSaveModuleData({ state, rootState }) {
    const token = rootState.auth.userToken;
    apiRoutes
      .postAddModuleData(token, state.moduleName, state.formData)
      .then(response => {
        if (response.data[0] !== "ERROR") {
          // do nothing with response!
        }
      })
      .catch(error => {
        console.log(error);
      });
  },

  /**
   * Get company moduleData for validation purpose in send-module.
   * Used in Card.vue with custom validation instead of json-driven
   * approach using vuelidate.
   * */
  async loadCompanymoduleData({ commit, state, rootState }) {
    const token = rootState.auth.userToken;
    commit("set_status", 1);

    try {
      const response = await apiRoutes.getModuleData(token, state.moduleName);
      if (response.data[0] === "SUCCESS") {
        let moduleData = response.data[2];
        commit("set_status", 2);
        return JSON.parse(moduleData);
      } else return { locations: [] }; // if no module-data exists return empty locations-array in order to generate UI error
    } catch (error) {
      console.log(error);
      commit("set_status", 3);
    }
  },

  /**
   * Adds the locationID/facilityID of selected
   * item/location in order to build the combined
   * token per location. Used in CoreNavigationItem.vue
   * and DataTable.vue
   * */
  addSingleLocationID: ({ commit }, payload) => {
    if (payload !== undefined || payload !== null) {
      commit("set_singleLocationID", payload);
    } else {
      commit("set_singleLocationID", null);
    }
  },

  /**
   * Clears the locationID from store and localStorage.
   * Was intended for router.afterEach-hook, but it is
   * not a good idea, due to the fact that browser history
   * back and forward would crash.
   * */
  clearSingleLocationID: ({ commit, state }) => {
    localStorage.removeItem("locationID");
    state.locationID = null;
    commit("set_singleLocationID", null);
  },

  /**
   * Done on company/user-logout in auth-module.
   * */
  clearCompanyState: ({ commit, dispatch }) => {
    dispatch("clearSingleLocationID");
    commit("set_initialFormDataC");
  },

  loadProductionTypesList: ({ state, rootState, rootGetters }) => {
    const token = rootState.auth.loginToken;
    let locale = rootGetters["auth/getLang"];
    let payload = {
      token: token,
      moduleName: state.moduleName,
      listName: "productiontypes",
      mutationName: "set_productionTypes",
      locale: locale,
      name: "Name",
      value: "ID"
    };
    apiCalls.getList(payload);
  }
};

const mutations = {
  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  set_updateCompany(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  set_hideCompany(state) {
    if (state.formData.billingAddressChoose === true) {
      state.schema.baCompanyName.hidden = true;
      state.schema.baInformation.hidden = true;
      [
        "baCompanyName",
        "baCompanyAddress",
        "baPostalCode",
        "baLocation",
        "baCountry",
        "baVAT",
        "baBillingEmail"
      ].forEach(k => Vue.delete(state.formData, `${k}`));
    } else if (state.formData.billingAddressChoose === false) {
      state.schema.baCompanyName.hidden = false;
      state.schema.baInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  set_status(state, payload) {
    state.status = payload;
  },

  /**
   * Setter for locationID and editable-status for freshly
   * added location in locations-array.
   * */
  set_locationID(state, payload) {
    state.formData.locations.forEach(function(item) {
      if (item.uID === payload.uID) {
        item.locationID = payload.locationID;
        if (!item.hasOwnProperty("editable")) {
          item.editable = true;
        }
      }
    });
  },

  /**
   * Setter to change formData-object
   * upon any api-call.
   * */
  set_company(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for locationID aka facilityToken.
   * See addSingleLocationID.
   * */
  set_singleLocationID(state, payload) {
    if (payload !== null) {
      state.locationID = payload;
      localStorage.setItem("locationID", payload);
      if (isNotEmptyArray(state.formData.locations)) {
        const name = state.formData.locations.find(
          x => x.locationID === state.locationID
        );
        localStorage.setItem("locationName", name.facilityName);
      }
    } else {
      state.locationID = null;
      localStorage.removeItem("locationID");
      localStorage.removeItem("locationName");
    }
  },

  /**
   * Set editability for company data due to approval.
   * */
  set_editableCompany(state, payload) {
    state.editable = payload;
  },

  set_initialFormDataC(state) {
    //Vue.set(state, "formData", initialFormData);
    Vue.delete(state.formData, "companyName");
    state.formData = Object.assign({}, initialFormData());
  },

  set_productionTypes(state, payload) {
    state.productionTypes = payload;
    //mutate formData locations->productionType translation if existent
    if (isNotEmptyArray(state.formData.locations)) {
      state.formData.locations.forEach(location => {
        let obj = location.productionType;
        Object.assign(
          obj,
          state.productionTypes.find(
            x => x.itemValue === location.productionType.itemValue
          )
        );
      });
    }
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => Company.vue
   * */
  get_companyData: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => Company.vue
   * */
  get_companySchema: state => {
    return state.schema;
  },

  /**
   * Getter for locations-array.
   * Used to build main-navigation => CoreNavigationItem.vue
   * and final validation before the whole request is submitted.
   * => send-module/Card.vue
   * */
  get_locations: state => {
    return state.formData.locations;
  },

  /**
   * The hint that this getter is not used is to be
   * ignored! It is used in almost every module in
   * order to build the combined token per location.
   * */
  get_singleLocationID: state => {
    return state.locationID;
  },

  /**
   * Get location name of specific facility.
   * Used in facility modules.
   *
   * @return string
   * */
  get_locationName: state => {
    if (isNotEmptyArray(state.formData.locations)) {
      const name = state.formData.locations.find(
        x => x.locationID === state.locationID
      );
      return name ? name.facilityName : null;
    }
  },

  /**
   * Get editability of company data (aside locations array)
   * */
  get_companyEditable: state => {
    return state.editable;
  },

  /**
   * Get country. Returns boolean for Austria.
   *
   * @param   {Object} state
   * @return  {boolean}
   */
  get_isAustria: state => {
    return (
      typeof state.formData.caCountry !== "undefined" &&
      state.formData.caCountry.itemValue === "GlobalCountry_2"
    );
  },

  /**
   * ignore the unused hint
   * */
  get_productionTypes: state => state.productionTypes
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
