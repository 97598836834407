import apiRoutes from "./apiRoutes";
import store from "../store";
import { capitalizeFirstLetter, stringIsInteger } from "@/helpers/helpers";
import { alterModuleData } from "@/helpers/dataMapper";

/* eslint-disable no-console */
export default {
  getModuleData: function(payload) {
    let { token, moduleName } = payload;
    let capModuleName = capitalizeFirstLetter(moduleName);
    store.commit(moduleName + "/setStatus" + capModuleName, 1, { root: true });
    apiRoutes
      .getModuleData(token, moduleName)
      .then(response => {
        if (response.data[0] === "ERROR") {
          if (response.data[1] === "no moduledata found") {
            store.commit(
              moduleName + "/setInitialFormData" + capModuleName,
              null,
              { root: true }
            );
            store.commit(moduleName + "/setEditable" + capModuleName, true, {
              root: true
            }); //as editable is not set in error, it must be done explicitly
            store.commit(moduleName + "/setStatus" + capModuleName, 2, {
              root: true
            });
          } else {
            store.commit(moduleName + "/setStatus" + capModuleName, 3, {
              root: true
            });
          }
        } else {
          // alter old module data in outsourced function
          let moduleData = alterModuleData(
            moduleName,
            JSON.parse(response.data[2])
          );

          store.commit(
            moduleName + "/setEditable" + capModuleName,
            response.data[3] === "editable",
            { root: true }
          );
          store.commit(moduleName + "/set" + capModuleName, moduleData, {
            root: true
          });
          store.commit(moduleName + "/setHide" + capModuleName, null, {
            root: true
          });
          store.commit(moduleName + "/setStatus" + capModuleName, 2, {
            root: true
          });
        }
      })
      .catch(error => {
        console.log(error);
        store.commit(moduleName + "/setStatus" + capModuleName, 3, {
          root: true
        });
        store.commit("global_errorStatus", true, { root: true });
        store.commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Add or update module data.
   * Actual saving functionality.
   *
   * This method will replace the often
   * repeated actions in each module.
   * */
  addModuleData: function(payload) {
    let { token, moduleName, formData } = payload;
    let capModuleName = capitalizeFirstLetter(moduleName);
    store.commit(moduleName + "/setStatus" + capModuleName, 1);
    apiRoutes
      .postAddModuleData(token, moduleName, formData)
      .then(response => {
        if (response.data[0] === "ERROR") {
          store.commit(moduleName + "/setStatus" + capModuleName, 3);
          store.commit("global_errorStatus", true, { root: true });
          store.commit(
            "global_error",
            { message: "errors.servererrorsave" },
            { root: true }
          );
        } else {
          store.dispatch(moduleName + "/load" + capModuleName);
          store.commit(moduleName + "/setStatus" + capModuleName, 2);
          store.commit("global_successStatus", true, { root: true });
          store.commit("global_success", true, { root: true });
          setTimeout(() => {
            store.commit("global_clearSuccess", null, { root: true });
          }, 4000);
        }
      })
      .catch(error => {
        console.log(error);
        store.commit(moduleName + "/setStatus" + capModuleName, 3);
        store.commit("global_errorStatus", true, { root: true });
        store.commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  addFile: function(data) {
    let { token, moduleName, payload, existFiles } = data;
    let capModuleName = capitalizeFirstLetter(moduleName);
    store.commit(moduleName + "/setStatus" + capModuleName, 1);
    apiRoutes
      .postAddFile(
        token,
        payload[0].fieldName,
        payload[0].stableID,
        payload[0].uID,
        payload[0].fileName,
        payload[0].fileType,
        payload[0].fileSize,
        payload[0].fileContent
      )
      .then(r => {
        if (r.data[0] === "ERROR") {
          store.commit(moduleName + "/setStatus" + capModuleName, 3);
          store.commit("global_errorStatus", true, { root: true });
          store.commit(
            "global_error",
            { message: "errors.servererrorsave" },
            { root: true }
          );
        } else {
          store.commit(moduleName + "/setFile" + capModuleName, existFiles);
          store.dispatch(moduleName + "/add" + capModuleName);
          store.commit(moduleName + "/setStatus" + capModuleName, 2);
        }
      })
      .catch(error => {
        console.log(error);
        store.commit(moduleName + "/setStatus" + capModuleName, 3);
        store.commit("global_errorStatus", true, { root: true });
        store.commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * @param {Object} payload
   */
  getList: function(payload) {
    let {
      token,
      moduleName,
      listName,
      locale,
      mutationName,
      name,
      value,
      add,
      addName,
      addValue,
      addPrefix,
      prefix
    } = payload;
    apiRoutes
      .getList(token, listName, locale)
      .then(response => {
        if (response.data[0] === "ERROR") {
          console.log(response.data[1]);
        } else {
          let data = response.data[1].map(obj => {
            if (addPrefix) {
              return {
                itemText: obj[name], // column name
                itemValue: prefix + obj[value] // column ID or appcode + added prefix
              };
            } else {
              return {
                itemText: obj[name], // column name
                itemValue: stringIsInteger(obj[value])
                  ? +obj[value]
                  : obj[value] // column ID or appcode - if is int, cast to number
              };
            }
          });
          if (add) {
            let other = {
              itemText: addName,
              itemValue: addValue
            };
            data.push(other);
          }
          if (moduleName) {
            store.commit(moduleName + "/" + mutationName, data); // module store
          } else {
            store.commit(mutationName, data); // root store
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
