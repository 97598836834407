import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:("height: 100%"),attrs:{"id":"core-view"}},[_c(VMain,{staticClass:"fill-height bg-image",style:(_vm.$vuetify.breakpoint.smAndUp
        ? ("background-image: url(" + (require('../../assets/reg_bg_fhd.jpg')) + ")")
        : this.$route.name === 'login'
        ? ("background-image: url(" + (require('../../assets/reg_bg_fhd.jpg')) + ")")
        : this.$route.name === 'registration'
        ? ("background-image: url(" + (require('../../assets/reg_bg_fhd.jpg')) + ")")
        : "background-color: white")},[_c(VContainer,{attrs:{"fluid":_vm.fluid,"pa-xl-12":"","pa-lg-11":"","pa-md-10":"","pa-sm-6":"","pa-xs-0":""}},[_c(VFadeTransition,{attrs:{"mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }