/* eslint-disable no-console */

/**
 * Maps facilities to routes. Builds navigation in Sidebar from
 * facilities in DB and store
 * */
function facilitiesToRoutes(facility) {
  if (facility.productionType === undefined) {
    return console.log("not set yet");
  }
  if (facility.productionType.itemValue === 2) {
    return {
      path: { name: "hatchery", params: { uID: facility.uID.toString() } },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 2,
      title: "Brüterei",
      icon: "$mdiHomeGroup"
    };
  }
  if (facility.productionType.itemValue === 32) {
    return {
      path: { name: "preraiser", params: { uID: facility.uID.toString() } },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 2,
      title: "Voraufzuchtbetrieb",
      icon: "$mdiHomeCity"
    };
  }
  if (facility.productionType.itemValue === 1) {
    return {
      path: { name: "rearer", params: { uID: facility.uID.toString() } },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 1,
      title: "Aufzuchtbetrieb",
      icon: "$mdiBarn"
    };
  }
  if (facility.productionType.itemValue === 15) {
    return {
      path: { name: "layingfarm", params: { uID: facility.uID.toString() } },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 15,
      title: "Legebetrieb",
      icon: "$mdiEgg"
    };
  }
  if (facility.productionType.itemValue === 23) {
    return {
      path: {
        name: "homecompounders",
        params: { uID: facility.uID.toString() }
      },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 23,
      title: "Selbstmischer",
      icon: "$mdiCorn"
    };
  }
  if (facility.productionType.itemValue === 20) {
    return {
      path: { name: "packing", params: { uID: facility.uID.toString() } },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 20,
      title: "Packstelle",
      icon: "$mdiPackageVariantClosed"
    };
  }
  if (facility.productionType.itemValue === 8) {
    return {
      path: { name: "dyehouse", params: { uID: facility.uID.toString() } },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 8,
      title: "Färberei",
      icon: "$mdiEggEaster"
    };
  }
  if (facility.productionType.itemValue === 7) {
    return {
      path: { name: "feedmills", params: { uID: facility.uID.toString() } },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 7,
      title: "Futtermittelwerk",
      icon: "$mdiSilo"
    };
  }
  if (
    facility.productionType.itemValue === 16 ||
    facility.productionType.itemValue === 26 ||
    facility.productionType.itemValue === 29 ||
    facility.productionType.itemValue === 33
  ) {
    return {
      path: { name: "dealers", params: { uID: facility.uID.toString() } },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 998,
      title: "Händler",
      icon: "$mdiCogTransfer"
    };
  }
  if (
    facility.productionType.itemValue === 5 ||
    facility.productionType.itemValue === 19
  ) {
    return {
      path: {
        name: "eggfoodfacility",
        params: { uID: facility.uID.toString() }
      },
      name: facility.facilityName,
      uID: facility.uID.toString(),
      locationID: facility.locationID,
      type: 999,
      title: "Eiprodukte/Nahrungsmittelwerk",
      icon: "$mdiFactory"
    };
  }
}

function productionTypeToStoreModule(productionType) {
  switch (productionType) {
    case 2:
      return "hatchery";
    case 32:
      return "preraiser";
    case 1:
      return "rearer";
    case 15:
      return "layingFarm";
    case 23:
      return "homeCompounders";
    case 20:
      return "packing";
    case 8:
      return "dyehouse";
    case 7:
      return "feedMills";
    case 16:
    case 26:
    case 29:
    case 33:
      return "dealers";
    case 5:
    case 19:
      return "eggFoodFacility";
  }
}

function productionTypeToErrorMessage(productionType) {
  switch (productionType) {
    case 2:
      return "{{hatcheryDetails}}";
    case 32:
      return "{{preraiserDetails}}";
    case 1:
      return "{{rearerDetails}}";
    case 15:
      return "{{layingFarmDetails}}";
    case 23:
      return "{{homeCompoundersDetails}}";
    case 20:
      return "{{packingDetails}}";
    case 8:
      return "{{dyehouseDetails}}";
    case 7:
      return "{{feedMillsDetails}}";
    case 16:
    case 26:
    case 29:
    case 33:
      return "{{dealersDetails}}";
    case 5:
    case 19:
      return "{{eggFoodFacilityDetails}}";
  }
}

export {
  facilitiesToRoutes,
  productionTypeToStoreModule,
  productionTypeToErrorMessage
};
