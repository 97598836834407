import confRearer from "../../conf/confRearer";
import apiRoutes from "../../api/apiRoutes";
import apiCalls from "@/api/apiCalls";
import Vue from "vue";
import { hashCode } from "@/helpers/helpers";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    rStables: [],
    suppliers: [],
    recipients: [],
    files: [],
    rRegulatoryApprovalExistent: null
  };
};

const state = () => ({
  moduleName: "rearer",
  formData: {
    rStables: [],
    suppliers: [],
    recipients: [],
    files: [],
    rRegulatoryApprovalExistent: null
  },
  schema: { ...confRearer.schema },
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * Sets uID for each stable.
   * */
  updateRearer: ({ commit }, payload) => {
    if (payload.rStables) {
      payload.rStables.forEach(function(item) {
        if (!Object.prototype.hasOwnProperty.call(item, "uID")) {
          item.stableID = hashCode(Date.now() + Math.random().toString());
        }
      });
    }
    commit("setUpdateRearer", payload);
    commit("setHideRearer");
  },

  /**
   * Add or update rearer's module data.
   * Actual saving functionality.
   * */
  addRearer: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load rearer's module data.
   * Done in rearer's view created hook.
   * */
  loadRearer: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  },

  /**
   * Get rearer moduleData for validation purpose in send-module.
   * Used in Card.vue with custom validation instead of json-driven
   * approach using vuelidate.
   * */
  async loadRmoduleData({ commit, state, rootState }, payload) {
    const userToken = rootState.auth.userToken;
    const token = userToken + payload;
    commit("setStatusRearer", 1);

    try {
      const response = await apiRoutes.getModuleData(token, state.moduleName);
      if (response.data[0] === "SUCCESS") {
        let moduleData = response.data[2];
        commit("setStatusRearer", 2);
        return JSON.parse(moduleData);
      } else return { rStables: [] }; // if no module-data exist return empty stable-array in order to generate UI error
    } catch (error) {
      console.log(error);
      commit("setStatusRearer", 3);
    }
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFileRearer: ({ state, rootState, rootGetters }, payload) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let existFiles = [...state.formData.files];
    let addFiles = payload;
    addFiles = addFiles.map(({ fieldName, stableID, fileName, uID }) => ({
      fieldName,
      stableID,
      fileName,
      uID
    }));
    addFiles.forEach(item => {
      existFiles.push(item);
    });
    let data = {
      token: token,
      moduleName: state.moduleName,
      payload: payload,
      existFiles: existFiles
    };
    apiCalls.addFile(data);
  },

  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFileRearer: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusRearer", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusRearer", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          let files = [...state.formData.files];
          files.splice(
            files.findIndex(function(i) {
              return i.uID === payload.uID;
            }),
            1
          );
          commit("setFileRearer", files);
          if (
            state.formData.files.length === 0 &&
            state.formData.rRegulatoryApprovalExistent === true
          ) {
            commit("setRegulatoryApprovalExistentRearer", null);
            commit("setRegulatoryApprovalFileListRearer");
          }
          dispatch("addRearer");
          commit("setStatusRearer", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusRearer", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  }
};

const mutations = {
  /**
   * If loadRearer returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataRearer(state) {
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confRearer.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateRearer(state, payload) {
    state.formData = payload;
  },

  /**
   * Set rRegulatoryApprovalExistent
   * */
  setRegulatoryApprovalExistentRearer(state, payload) {
    state.formData.rRegulatoryApprovalExistent = payload;
  },

  /**
   * Sets deletion of rRegulatoryApprovalFileList
   * */
  setRegulatoryApprovalFileListRearer(state) {
    Vue.delete(state.formData, "rRegulatoryApprovalFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideRearer(state) {
    if (state.formData.rRegulatoryApprovalExistent === true) {
      state.schema.rRegulatoryApprovalFile.hidden = false;
      state.schema.rRegulatoryApprovalLater.hidden = true;
      Vue.delete(state.formData, "rRegulatoryApprovalLater");
    } else if (state.formData.rRegulatoryApprovalExistent === false) {
      state.schema.rRegulatoryApprovalFile.hidden = true;
      state.schema.rRegulatoryApprovalLater.hidden = false;
      if (
        !!state.formData.rRegulatoryApprovalFileList &&
        state.formData.rRegulatoryApprovalFileList.length < 1
      ) {
        Vue.delete(state.formData, "rRegulatoryApprovalFileList");
      }
    }
    if (state.formData.rProductionSiteChoose === true) {
      state.schema.rInformation.hidden = true;
      [
        /*"rSpecialNumber",*/
        "rPlantManager",
        "rPmFirstName",
        "rPmLastName",
        "rPmPhone",
        "rPmMobile",
        "rPmFax",
        "rPmEmail",
        "rStreetNumber",
        "rPostalCode",
        "rLocation",
        "rCountry",
        "rRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.rProductionSiteChoose === false) {
      state.schema.rInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusRearer(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setRearer(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for file-objects.
   * */
  setFileRearer(state, payload) {
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for data due to approval.
   * */
  setEditableRearer(state, payload) {
    state.editable = payload;
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => Rearer.vue
   * */
  getDataRearer: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => Rearer.vue
   * */
  getSchemaRearer: state => {
    return state.schema;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList. Injected via
   * Injected via conf….json-file as a prop.
   * */
  getFilesRearer: state => {
    return state.formData.files;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  get_rRegulatoryApprovalExistent(state) {
    return state.formData.rRegulatoryApprovalExistent;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditableRearer: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
