import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import requestType from "./modules/requestType";
import company from "./modules/company";
import hatchery from "./modules/hatchery";
import preraiser from "./modules/preraiser";
import rearer from "./modules/rearer";
import homeCompounders from "./modules/homeCompounders";
import feedMills from "./modules/feedMills";
import layingFarm from "./modules/layingFarm";
import dyehouse from "./modules/dyehouse";
import packing from "./modules/packing";
import dealers from "./modules/dealers";
import eggFoodFacility from "./modules/eggFoodFacility";
import send from "./modules/send";
import validator from "./modules/validator";
import apiCalls from "@/api/apiCalls";

Vue.use(Vuex);

/* eslint-disable no-console */
export default new Vuex.Store({
  state: {
    error: null,
    errorStatus: false,
    success: null,
    successStatus: false,
    validateStatus: null,
    countries: [],
    henHouseManufacturers: [],
    certificationAuthorities: [],
    salutation: [],
    stableGenderType: [],
    farmingTypeRearer: [],
    farmingTypeLayingfarm: [],
    rearingType: []
  },

  actions: {
    loadCountryList: ({ rootState, rootGetters }) => {
      const token = rootState.auth.loginToken;
      let locale = rootGetters["auth/getLang"];
      let payload = {
        token: token,
        listName: "countries",
        mutationName: "global_countries",
        locale: locale,
        name: "Translation",
        value: "appcode",
        addPrefix: true,
        prefix: "GlobalCountry_"
      };
      apiCalls.getList(payload);
    },

    loadHenHouseManufacturerList: ({ rootState }) => {
      const token = rootState.auth.loginToken;
      let payload = {
        token: token,
        listName: "henhousemanufacturer",
        mutationName: "global_HenHouseManufacturers",
        name: "CompanyName",
        value: "ID",
        add: true,
        addName: "global.other",
        addValue: "-1" //map 'other' to -1
      };
      apiCalls.getList(payload);
    },

    loadCertificationAuthoritiesList: ({ rootState }) => {
      const token = rootState.auth.loginToken;
      let payload = {
        token: token,
        listName: "certificationauthorities",
        mutationName: "global_certificationAuthorities",
        name: "Name",
        value: "ID"
      };
      apiCalls.getList(payload);
    },

    loadSalutationList: ({ rootState, rootGetters }) => {
      const token = rootState.auth.loginToken;
      let locale = rootGetters["auth/getLang"];
      let payload = {
        token: token,
        listName: "salutation",
        mutationName: "global_salutation",
        locale: locale,
        name: "Translation",
        value: "appcode",
        addPrefix: true,
        prefix: "GlobalAddressSalutation_"
      };
      apiCalls.getList(payload);
    },

    loadStableGenderTypeList: ({ rootState, rootGetters }) => {
      const token = rootState.auth.loginToken;
      let locale = rootGetters["auth/getLang"];
      let payload = {
        token: token,
        listName: "stablegendertype",
        mutationName: "global_stableGenderType",
        locale: locale,
        name: "Translation",
        value: "appcode",
        addPrefix: true,
        prefix: "StableGenderType_"
      };
      apiCalls.getList(payload);
    },

    loadFarmingTypeRearerList: ({ rootState, rootGetters }) => {
      const token = rootState.auth.loginToken;
      let locale = rootGetters["auth/getLang"];
      let payload = {
        token: token,
        listName: "farmingtyperearer",
        mutationName: "global_farmingTypeRearer",
        locale: locale,
        name: "Name",
        value: "appcode",
        addPrefix: true,
        prefix: "GlobalFarmingType_"
      };
      apiCalls.getList(payload);
    },

    loadFarmingTypeLayingfarmList: ({ rootState, rootGetters }) => {
      const token = rootState.auth.loginToken;
      let locale = rootGetters["auth/getLang"];
      let payload = {
        token: token,
        listName: "farmingtypelayingfarm",
        mutationName: "global_farmingTypeLayingfarm",
        locale: locale,
        name: "Name",
        value: "appcode",
        addPrefix: true,
        prefix: "GlobalFarmingType_"
      };
      apiCalls.getList(payload);
    },

    loadRearingTypeList: ({ rootState, rootGetters }) => {
      const token = rootState.auth.loginToken;
      let locale = rootGetters["auth/getLang"];
      let payload = {
        token: token,
        listName: "rearingtype",
        mutationName: "global_rearingType",
        locale: locale,
        name: "Translation",
        value: "appcode",
        addPrefix: true,
        prefix: "GlobalRearingType_"
      };
      apiCalls.getList(payload);
    },

    loadGlobalLists: ({ dispatch }) => {
      void dispatch("loadCountryList");
      void dispatch("loadHenHouseManufacturerList");
      void dispatch("loadCertificationAuthoritiesList");
      void dispatch("loadSalutationList");
      void dispatch("loadStableGenderTypeList");
      void dispatch("loadFarmingTypeRearerList");
      void dispatch("loadFarmingTypeLayingfarmList");
      void dispatch("loadRearingTypeList");
    }
  },

  mutations: {
    global_error(state, payload) {
      state.error = payload;
    },
    global_errorStatus(state, payload) {
      state.errorStatus = payload;
    },
    global_clearError(state) {
      state.error = null;
      state.errorStatus = false;
    },
    global_success(state, payload) {
      state.success = payload;
    },
    global_successStatus(state, payload) {
      state.successStatus = payload;
    },
    global_clearSuccess(state) {
      state.success = null;
      state.successStatus = false;
    },
    global_validateStatus(state, payload) {
      state.validateStatus = payload;
    },
    global_countries(state, payload) {
      state.countries = payload;
    },
    global_HenHouseManufacturers(state, payload) {
      state.henHouseManufacturers = payload;
    },
    global_certificationAuthorities(state, payload) {
      state.certificationAuthorities = payload;
    },
    global_salutation(state, payload) {
      state.salutation = payload;
    },
    global_stableGenderType(state, payload) {
      state.stableGenderType = payload;
    },
    global_farmingTypeRearer(state, payload) {
      state.farmingTypeRearer = payload;
    },
    global_farmingTypeLayingfarm(state, payload) {
      state.farmingTypeLayingfarm = payload;
    },
    global_rearingType(state, payload) {
      state.rearingType = payload;
    }
  },

  getters: {
    get_global_error: state => state.error,
    get_global_errorStatus: state => state.errorStatus,
    get_global_success: state => state.success,
    get_global_successStatus: state => state.successStatus,
    get_global_validateStatus: state => state.validateStatus,
    get_global_countries: state => state.countries,
    get_global_HenHouseManufacturers: state => state.henHouseManufacturers,
    get_global_certificationAuthorities: state =>
      state.certificationAuthorities,
    get_global_salutation: state => state.salutation,
    get_global_stableGenderType: state => state.stableGenderType,
    get_global_farmingTypeRearer: state => state.farmingTypeRearer,
    get_global_farmingTypeLayingfarm: state => state.farmingTypeLayingfarm,
    get_global_rearingType: state => state.rearingType
  },

  modules: {
    auth,
    requestType,
    company,
    hatchery,
    preraiser,
    rearer,
    homeCompounders,
    feedMills,
    layingFarm,
    dyehouse,
    packing,
    dealers,
    eggFoodFacility,
    send,
    validator
  }
});
