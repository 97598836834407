import confEggFoodFacility from "../../conf/confEggFoodFacility";
import apiRoutes from "../../api/apiRoutes";
import Vue from "vue";
import apiCalls from "@/api/apiCalls";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    effProducts: [],
    suppliers: [],
    recipients: [],
    files: [],
    effGfsiCertificationExistent: null
  };
};

const state = () => ({
  moduleName: "eggFoodFacility",
  formData: {
    effProducts: [],
    suppliers: [],
    recipients: [],
    files: [],
    effGfsiCertificationExistent: null
  },
  schema: { ...confEggFoodFacility.schema },
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateEggFoodFacility: ({ commit }, payload) => {
    commit("setUpdateEggFoodFacility", payload);
    commit("setHideEggFoodFacility");
  },

  /**
   * Add or update eggFoodFacility's module data.
   * Actual saving functionality.
   * */
  addEggFoodFacility: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load eggFoodFacility's module data.
   * Done in eggFoodFacility's view created hook.
   * */
  loadEggFoodFacility: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFileEggFoodFacility: ({ state, rootState, rootGetters }, payload) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let existFiles = [...state.formData.files];
    let addFiles = payload;
    addFiles = addFiles.map(({ fieldName, stableID, fileName, uID }) => ({
      fieldName,
      stableID,
      fileName,
      uID
    }));
    addFiles.forEach(item => {
      existFiles.push(item);
    });
    let data = {
      token: token,
      moduleName: state.moduleName,
      payload: payload,
      existFiles: existFiles
    };
    apiCalls.addFile(data);
  },

  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFileEggFoodFacility: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusEggFoodFacility", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusEggFoodFacility", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          let files = [...state.formData.files];
          files.splice(
            files.findIndex(function(i) {
              return i.uID === payload.uID;
            }),
            1
          );
          commit("setFileEggFoodFacility", files);
          if (
            state.formData.files.length === 0 &&
            state.formData.effGfsiCertificationExistent === true
          ) {
            commit("setGfsiCertificationExistentEggFoodFacility", null);
            commit("setGfsiCertificationFileListEggFoodFacility");
          }
          dispatch("addEggFoodFacility");
          commit("setStatusEggFoodFacility", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusEggFoodFacility", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  }
};

const mutations = {
  /**
   * If loadEggFoodFacility returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataEggFoodFacility(state) {
    // Vue.set(state, "formData", initialFormData);
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confEggFoodFacility.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateEggFoodFacility(state, payload) {
    state.formData = payload;
  },

  /**
   * Set effGfsiCertificationExistent
   * */
  setGfsiCertificationExistentEggFoodFacility(state, payload) {
    state.formData.effGfsiCertificationExistent = payload;
  },

  /**
   * Set deletion of effGfsiCertificationFileList
   * */
  setGfsiCertificationFileListEggFoodFacility(state) {
    Vue.delete(state.formData, "effGfsiCertificationFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideEggFoodFacility(state) {
    if (state.formData.effGfsiCertificationExistent === true) {
      state.schema.effGfsiCertificationFile.hidden = false;
      state.schema.effGfsiCertificationLater.hidden = true;
      Vue.delete(state.formData, "effGfsiCertificationLater");
    } else if (state.formData.effGfsiCertificationExistent === false) {
      state.schema.effGfsiCertificationFile.hidden = true;
      state.schema.effGfsiCertificationLater.hidden = false;
      if (
        !!state.formData.effGfsiCertificationFileList &&
        state.formData.effGfsiCertificationFileList.length < 1
      ) {
        Vue.delete(state.formData, "effGfsiCertificationFileList");
      }
    }
    if (state.formData.effGfsiCertificationExistent === null) {
      state.schema.effGfsiCertificationFile.hidden = true;
      state.schema.effGfsiCertificationLater.hidden = true;
    }
    if (state.formData.effProductionSiteChoose === true) {
      state.schema.effInformation.hidden = true;
      [
        "effPlantManager",
        "effPmFirstName",
        "effPmLastName",
        "effPmPhone",
        "effPmMobile",
        "effPmFax",
        "effPmEmail",
        "effStreetNumber",
        "effPostalCode",
        "effLocation",
        "effCountry",
        "effRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.effProductionSiteChoose === false) {
      state.schema.effInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusEggFoodFacility(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setEggFoodFacility(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for file-objects.
   * */
  setFileEggFoodFacility(state, payload) {
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for data due to approval.
   * */
  setEditableEggFoodFacility(state, payload) {
    state.editable = payload;
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => EggFoodFacility.vue
   * */
  getDataEggFoodFacility: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => EggFoodFacility.vue
   * */
  getSchemaEggFoodFacility: state => {
    return state.schema;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList. Injected via
   * Injected via conf….json-file as a prop.
   * */
  getFilesEggFoodFacility: state => {
    return state.formData.files;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * */
  getGfsiCertificationExistentEggFoodFacility(state) {
    return state.formData.effGfsiCertificationExistent;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditableEggFoodFacility: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
