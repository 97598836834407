import confHatchery from "../../conf/confHatchery";
import apiRoutes from "../../api/apiRoutes";
import Vue from "vue";
import apiCalls from "@/api/apiCalls";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    suppliers: [],
    recipients: [],
    files: [],
    hRegistrationExistent: null
  };
};

const state = () => ({
  moduleName: "hatchery",
  formData: {
    suppliers: [],
    recipients: [],
    files: [],
    hRegistrationExistent: null
  },
  schema: { ...confHatchery.schema },
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateHatchery: ({ commit }, payload) => {
    commit("setUpdateHatchery", payload);
    commit("setHideHatchery");
  },

  /**
   * Add or update hatchery's module data.
   * Actual saving functionality.
   * */
  addHatchery: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load hatchery's module data.
   * Done in hatchery's view created hook.
   * */
  loadHatchery: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFileHatchery: ({ state, rootState, rootGetters }, payload) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let existFiles = [...state.formData.files];
    let addFiles = payload;
    addFiles = addFiles.map(({ fieldName, stableID, fileName, uID }) => ({
      fieldName,
      stableID,
      fileName,
      uID
    }));
    addFiles.forEach(item => {
      existFiles.push(item);
    });
    let data = {
      token: token,
      moduleName: state.moduleName,
      payload: payload,
      existFiles: existFiles
    };
    apiCalls.addFile(data);
  },
  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFileHatchery: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusHatchery", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusHatchery", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          let files = [...state.formData.files];
          files.splice(
            files.findIndex(function(i) {
              return i.uID === payload.uID;
            }),
            1
          );
          commit("setFileHatchery", files);
          if (
            state.formData.files.length === 0 &&
            state.formData.hRegistrationExistent === true
          ) {
            commit("setRegistrationExistentHatchery", null);
            commit("setRegistrationFileListHatchery");
          }
          dispatch("addHatchery");
          commit("setStatusHatchery", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusHatchery", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  }
};

const mutations = {
  /**
   * If loadHatchery returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataHatchery(state) {
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confHatchery.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateHatchery(state, payload) {
    state.formData = payload;
  },

  /**
   * Set hRegistrationExistent
   * */
  setRegistrationExistentHatchery(state, payload) {
    state.formData.hRegistrationExistent = payload;
  },

  /**
   * Set deletion of hRegistrationFileList
   * */
  setRegistrationFileListHatchery(state) {
    Vue.delete(state.formData, "hRegistrationFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideHatchery(state) {
    if (state.formData.hRegistrationExistent === true) {
      state.schema.hRegistrationFile.hidden = false;
      state.schema.hRegistrationFileLater.hidden = true;
      Vue.delete(state.formData, "hRegistrationFileLater");
    } else if (state.formData.hRegistrationExistent === false) {
      state.schema.hRegistrationFile.hidden = true;
      state.schema.hRegistrationFileLater.hidden = false;
      if (
        !!state.formData.hRegistrationFileList &&
        state.formData.hRegistrationFileList.length < 1
      ) {
        Vue.delete(state.formData, "hRegistrationFileList");
      }
    }
    if (state.formData.hRegistrationExistent === null) {
      state.schema.hRegistrationFile.hidden = true;
      state.schema.hRegistrationFileLater.hidden = true;
    }
    if (state.formData.hProductionSiteChoose === true) {
      state.schema.hInformation.hidden = true;
      [
        /*"hSpecialNumber",*/
        "hPlantManager",
        "hPmFirstName",
        "hPmLastName",
        "hPmPhone",
        "hPmMobile",
        "hPmFax",
        "hPmEmail",
        "hStreetNumber",
        "hPostalCode",
        "hLocation",
        "hCountry",
        "hRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.hProductionSiteChoose === false) {
      state.schema.hInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusHatchery(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setHatchery(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for file-objects.
   * */
  setFileHatchery(state, payload) {
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for data due to approval.
   * */
  setEditableHatchery(state, payload) {
    state.editable = payload;
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => Hatchery.vue
   * */
  getDataHatchery: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => Hatchery.vue
   * */
  getSchemaHatchery: state => {
    return state.schema;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList. Injected via
   * Injected via conf….json-file as a prop.
   * */
  getFilesHatchery: state => {
    return state.formData.files;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getRegistrationExistentHatchery(state) {
    return state.formData.hRegistrationExistent;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditableHatchery: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
