import confLayingFarm from "../../conf/confLayingFarm";
import apiRoutes from "../../api/apiRoutes";
import apiCalls from "../../api/apiCalls";
import Vue from "vue";
import { hashCode } from "@/helpers/helpers";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    lfStables: [],
    suppliers: [],
    recipients: [],
    files: [],
    lfRegulatoryApprovalExistent: null
  };
};

const state = () => ({
  moduleName: "layingFarm",
  formData: {
    lfStables: [],
    suppliers: [],
    recipients: [],
    files: [],
    lfRegulatoryApprovalExistent: null
  },
  schema: { ...confLayingFarm.schema },
  lfTempStableID: null,
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateLayingFarm: ({ commit, dispatch }, payload) => {
    if (payload.lfStables) {
      payload.lfStables.forEach(function(item) {
        if (!Object.prototype.hasOwnProperty.call(item, "stableID")) {
          item.stableID = hashCode(Date.now() + Math.random().toString());
        }
      });
    }
    commit("setUpdateLayingFarm", payload);
    commit("setHideLayingFarm");
    dispatch("checkIsAustria");
  },

  /**
   * Add or update layingFarm's module data.
   * Actual saving functionality.
   * */
  addLayingFarm: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load layingFarm's module data.
   * Done in layingFarm's view created hook.
   * */
  loadLayingFarm: ({ dispatch, state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
    dispatch("loadHenHouseManufacturerList", null, { root: true });
  },

  /**
   * Get layingFarm moduleData for validation purpose in send-module.
   * Used in Card.vue with custom validation instead of json-driven
   * approach using vuelidate.
   * */
  async loadLFmoduleData({ commit, state, rootState }, payload) {
    const userToken = rootState.auth.userToken;
    const token = userToken + payload;
    commit("setStatusLayingFarm", 1);

    try {
      const response = await apiRoutes.getModuleData(token, state.moduleName);
      if (response.data[0] === "SUCCESS") {
        let moduleData = response.data[2];
        commit("setStatusLayingFarm", 2);
        return JSON.parse(moduleData);
      } else return { lfStables: [] }; // if no module-data exist return empty stable-array in order to generate UI error
    } catch (error) {
      console.log(error);
      commit("setStatusLayingFarm", 3);
    }
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFileLayingFarm: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let stableFiles = [];
    let existFiles = [...state.formData.files];
    /**
     * if = stable related file
     * else = location related files
     * */
    if (payload[0].stableID !== 0) {
      let tempStables = [...state.formData.lfStables];
      tempStables.forEach(stable => {
        if (stable.stableID === payload[0].stableID) {
          if (stable.files === undefined) {
            Vue.set(stable, "files", []);
          }
          let addFiles = payload;
          addFiles = addFiles.map(({ fieldName, stableID, fileName, uID }) => ({
            fieldName,
            stableID,
            fileName,
            uID
          }));
          addFiles.forEach(item => stable.files.push(item));
        }
        stableFiles.push(stable);
      });
    } else {
      let addFiles = payload;
      addFiles = addFiles.map(({ fieldName, stableID, fileName, uID }) => ({
        fieldName,
        stableID,
        fileName,
        uID
      }));
      addFiles.forEach(item => {
        existFiles.push(item);
      });
    }
    commit("setStatusLayingFarm", 1);
    apiRoutes
      .postAddFile(
        token,
        payload[0].fieldName,
        payload[0].stableID,
        payload[0].uID,
        payload[0].fileName,
        payload[0].fileType,
        payload[0].fileSize,
        payload[0].fileContent
      )
      .then(r => {
        if (r.data[0] === "ERROR") {
          commit("setStatusLayingFarm", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrorsave" },
            { root: true }
          );
        } else {
          if (payload[0].stableID !== 0) {
            commit("setStableFileLayingFarm", stableFiles);
          } else {
            commit("setFileLayingFarm", existFiles);
          }
          dispatch("addLayingFarm");
          commit("setStatusLayingFarm", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusLayingFarm", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFileLayingFarm: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusLayingFarm", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusLayingFarm", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          /**
           * if = stable related file
           * else = location related files
           * */
          if (payload.stableID !== 0) {
            let tempStables = [...state.formData.lfStables];
            let tempfiles = [];
            tempStables.forEach(stable => {
              if (stable.stableID === payload.stableID) {
                stable.files.splice(
                  stable.files.findIndex(function(i) {
                    return i.uID === payload.uID;
                  }),
                  1
                );
                if (stable.files.length === 0) {
                  Vue.delete(stable, "proofOfAreaFileList");
                }
              }
              tempfiles.push(stable);
            });
            commit("setStableFileLayingFarm", tempfiles);
          } else if (payload.stableID === 0) {
            let files = [...state.formData.files];
            files.splice(
              files.findIndex(function(i) {
                return i.uID === payload.uID;
              }),
              1
            );
            commit("setFileLayingFarm", files);
            if (
              state.formData.files.length === 0 &&
              state.formData.lfRegulatoryApprovalExistent === true
            ) {
              commit("setRegulatoryApprovalExistentLayingFarm", null);
              commit("setRegulatoryApprovalFileListLayingFarm");
            }
          }
          dispatch("addLayingFarm");
          commit("setStatusLayingFarm", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusLayingFarm", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  },

  addLfTempStableID: ({ commit }, payload) => {
    commit("setTemporaryStableIDLayingFarm", payload);
  },

  /**
   * Check laying-farm or company is set in Austria.
   *
   * @param commit
   * @param state
   * @param rootGetters
   */
  checkIsAustria: ({ commit, state, rootGetters }) => {
    let ownAddress = state.formData.lfProductionSiteChoose === false;
    let companyIsAustria = rootGetters["company/get_isAustria"];
    let facilityIsAustria =
      typeof state.formData.lfCountry !== "undefined" &&
      state.formData.lfCountry.itemValue === "GlobalCountry_2";
    let payload = {
      ownAddress: ownAddress,
      companyIsAustria: companyIsAustria,
      facilityIsAustria: facilityIsAustria
    };
    commit("setAMACertificationLayingFarm", payload);
  }
};

const mutations = {
  /**
   * If loadLayingFarm returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   *
   * @param {Object}  state
   * */
  setInitialFormDataLayingFarm(state) {
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confLayingFarm.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateLayingFarm(state, payload) {
    state.formData = payload;
  },

  /**
   * Set lfRegulatoryApprovalExistent
   * */
  setRegulatoryApprovalExistentLayingFarm(state, payload) {
    state.formData.lfRegulatoryApprovalExistent = payload;
  },

  /**
   * Sets deletion of lfRegulatoryApprovalFileList
   * */
  setRegulatoryApprovalFileListLayingFarm(state) {
    Vue.delete(state.formData, "lfRegulatoryApprovalFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   *
   * @param {Object}  state
   * */
  setHideLayingFarm(state) {
    if (state.formData.lfRegulatoryApprovalExistent === true) {
      state.schema.lfRegulatoryApprovalFile.hidden = false;
      state.schema.lfRegulatoryApprovalLater.hidden = true;
      Vue.delete(state.formData, "lfRegulatoryApprovalLater");
    } else if (state.formData.lfRegulatoryApprovalExistent === false) {
      state.schema.lfRegulatoryApprovalFile.hidden = true;
      state.schema.lfRegulatoryApprovalLater.hidden = false;
      if (
        !!state.formData.lfRegulatoryApprovalFileList &&
        state.formData.lfRegulatoryApprovalFileList.length < 1
      ) {
        Vue.delete(state.formData, "lfRegulatoryApprovalFileList");
      }
    }
    if (state.formData.lfRegulatoryApprovalExistent === null) {
      state.schema.lfRegulatoryApprovalFile.hidden = true;
      state.schema.lfRegulatoryApprovalLater.hidden = true;
    }
    if (state.formData.lfProductionSiteChoose === true) {
      state.schema.lfInformation.hidden = true;
      [
        "lfPlantManager",
        "lfPmFirstName",
        "lfPmLastName",
        "lfPmPhone",
        "lfPmMobile",
        "lfPmFax",
        "lfPmEmail",
        "lfStreetNumber",
        "lfPostalCode",
        "lfLocation",
        "lfCountry",
        "lfRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.lfProductionSiteChoose === false) {
      state.schema.lfInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   *
   * @param {Object}  state
   * @param {number} payload
   */
  setStatusLayingFarm(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setLayingFarm(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for lfStables-file-objects.
   * */
  setStableFileLayingFarm(state, payload) {
    // state.formData.lfStables = payload;
    Vue.set(state.formData, "lfStables", payload);
  },

  /**
   * Setter for file-objects.
   * */
  setFileLayingFarm(state, payload) {
    // state.formData.files = payload;
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Setter for preparing stable-level file-objects.
   * */
  setTemporaryStableIDLayingFarm(state, payload) {
    state.lfTempStableID = payload;
  },

  /**
   * Set editability for data due to approval.
   *
   * @param {Object}  state
   * @param {boolean} payload
   */
  setEditableLayingFarm(state, payload) {
    state.editable = payload;
  },

  /**
   * Set radio "AMA-Certification" for austrian laying-farm-facilities/companies.
   *
   * @param {Object} state
   * @param {Object} payload
   */
  setAMACertificationLayingFarm(state, payload) {
    if (
      (payload.companyIsAustria && !payload.ownAddress) ||
      (payload.ownAddress && payload.facilityIsAustria)
    ) {
      if (
        !state.schema.lfAMACertificationExistent.hasOwnProperty("validations")
      ) {
        let obj = {
          required: {
            params: null,
            message: "validationMessages.textRequired"
          }
        };
        Vue.set(state.schema.lfAMACertificationExistent, "validations", obj);
      }
      state.schema.lfAMACertificationExistent.hidden = false;
      state.schema.lfAMACertificationExistent.required = true;
      state.schema.lfAMACertificationExistent.toValidate = true;
    } else {
      state.schema.lfAMACertificationExistent.hidden = true;
      state.schema.lfAMACertificationExistent.required = false;
      state.schema.lfAMACertificationExistent.toValidate = false;
      Vue.delete(state.formData, "lfAMACertificationExistent");
      Vue.delete(state.schema.lfAMACertificationExistent, "validations");
    }
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => LayingFarm.vue
   * */
  getDataLayingFarm: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => LayingFarm.vue
   * */
  getSchemaLayingFarm: state => {
    return state.schema;
  },

  /**
   * The hint that this getter is not used
   * is to be ignored! Used in FileInput
   * and FileSelectList.
   * */
  getTemporaryStableIDLayingFarm: state => {
    return state.lfTempStableID;
  },

  /**
   * Ignore the unused property-hint!
   * Used in FileSelectList. Injected via
   * Injected via conf….json-file as a prop.
   * */
  getFilesLayingFarm: state => {
    return state.formData.files;
  },

  /**
   * Ignore the unused property-hint!
   * Used in FileSelectList in lfStables.
   * Injected via conf….json-file as a prop.
   * */
  getStableFilesLayingFarm(state) {
    return stableID =>
      state.formData.lfStables.find(stable => {
        if (stable.stableID === stableID && stable.files !== undefined) {
          return stable;
        } else return null;
      });
  },

  /**
   * Ignore the unused property-hint!
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getRegulatoryApprovalExistentLayingFarm(state) {
    return state.formData.lfRegulatoryApprovalExistent;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditableLayingFarm: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
