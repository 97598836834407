<template>
  <div id="core-view" :style="`height: 100%`">
    <v-main
      :style="
        $vuetify.breakpoint.smAndUp
          ? `background-image: url(${require('../../assets/reg_bg_fhd.jpg')})`
          : this.$route.name === 'login'
          ? `background-image: url(${require('../../assets/reg_bg_fhd.jpg')})`
          : this.$route.name === 'registration'
          ? `background-image: url(${require('../../assets/reg_bg_fhd.jpg')})`
          : `background-color: white`
      "
      class="fill-height bg-image"
    >
      <v-container :fluid="fluid" pa-xl-12 pa-lg-11 pa-md-10 pa-sm-6 pa-xs-0>
        <v-fade-transition mode="out-in">
          <router-view :key="$route.fullPath" />
        </v-fade-transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "CoreView",
  computed: {
    fluid() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
</script>

<style scoped>
.bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}
/*#core-view {*/
/*background: url("../../assets/reg_bg_fhd.jpg") center center;*/
/*background-size: cover;*/
/*height: 100%;*/
/*width: 100%;*/
/*!*padding: 0;*!*/
/*!*margin: 0;*!*/
/*}*/
</style>
