import confFeedMills from "../../conf/confFeedMills.json";
import apiRoutes from "../../api/apiRoutes";
import Vue from "vue";
import apiCalls from "@/api/apiCalls";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    suppliers: [],
    recipients: [],
    files: [],
    fmCurrentCertificatesExistent: null,
    fmRegistrationNumberExistent: null
  };
};

const state = () => ({
  moduleName: "feedMills",
  formData: {
    suppliers: [],
    recipients: [],
    files: [],
    fmCurrentCertificatesExistent: null,
    fmRegistrationNumberExistent: null
  },
  schema: { ...confFeedMills.schema },
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateFeedMills: ({ commit }, payload) => {
    commit("setUpdateFeedMills", payload);
    commit("setHideFeedMills");
  },

  /**
   * Add or update feedMills' module data.
   * Actual saving functionality.
   * */
  addFeedMills: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load feedMills' module data.
   * Done in feedMills' view created hook.
   * */
  loadFeedMills: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  },

  /**
   * File Upload. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileInput".
   * */
  addFileFeedMills: ({ state, rootState, rootGetters }, payload) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    if (payload[0].fieldName === "fmCurrentCertificatesFile") {
      payload[0].fileListName = "fmCurrentCertificatesFileList";
    } else if (payload[0].fieldName === "fmRegistrationNumberFile") {
      payload[0].fileListName = "fmRegistrationNumberFileList";
    }
    let existFiles = [...state.formData.files];
    let addFiles = payload;
    addFiles = addFiles.map(
      ({ fieldName, stableID, fileName, uID, fileListName }) => ({
        fieldName,
        stableID,
        fileName,
        uID,
        fileListName
      })
    );
    addFiles.forEach(item => {
      existFiles.push(item);
    });
    let data = {
      token: token,
      moduleName: state.moduleName,
      payload: payload,
      existFiles: existFiles
    };
    apiCalls.addFile(data);
  },

  /**
   * Delete File. Ignore the unused property hint!
   * This action is injected via props from conf….json-files
   * under the respective fieldType "FileSelectList".
   * */
  deleteFileFeedMills: (
    { commit, dispatch, state, rootState, rootGetters },
    payload
  ) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    commit("setStatusFeedMills", 1);
    apiRoutes
      .getDeleteFile(token, payload.fieldName, payload.stableID, payload.uID)
      .then(response => {
        if (response.data[0] === "ERROR") {
          commit("setStatusFeedMills", 3);
          commit("global_errorStatus", true, { root: true });
          commit(
            "global_error",
            { message: "errors.servererrordelete" },
            { root: true }
          );
        } else {
          let files = [...state.formData.files];
          files.splice(
            files.findIndex(function(i) {
              return i.uID === payload.uID;
            }),
            1
          );
          commit("setFileFeedMills", files);
          if (
            payload.fileListName === "fmCurrentCertificatesFileList" &&
            state.formData.files.filter(
              files => files.fileListName === payload.fileListName
            ).length === 0 &&
            state.formData.fmCurrentCertificatesExistent === true
          ) {
            commit("setCurrentCertificatesExistentFeedMills", null);
            commit("setCurrentCertificatesFileListFeedMills");
          } else if (
            payload.fileListName === "fmRegistrationNumberFileList" &&
            state.formData.files.filter(
              files => files.fileListName === payload.fileListName
            ).length === 0 &&
            state.formData.fmRegistrationNumberExistent === true
          ) {
            commit("setRegistrationNumberExistentFeedMills", null);
            commit("setRegistrationNumberFileListFeedMills");
          }
          dispatch("addFeedMills");
          commit("setStatusFeedMills", 2);
        }
      })
      .catch(error => {
        console.log(error);
        commit("setStatusFeedMills", 3);
        commit("global_errorStatus", true, { root: true });
        commit(
          "global_error",
          { message: "errors.networkerror" },
          { root: true }
        );
      });
  }
};

const mutations = {
  /**
   * If loadFeedMills returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataFeedMills(state) {
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confFeedMills.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateFeedMills(state, payload) {
    state.formData = payload;
  },

  /**
   * Set fmCurrentCertificatesExistent
   * */
  setCurrentCertificatesExistentFeedMills(state, payload) {
    state.formData.fmCurrentCertificatesExistent = payload;
  },

  /**
   * Set deletion of fmCurrentCertificatesFileList
   * */
  setCurrentCertificatesFileListFeedMills(state) {
    Vue.delete(state.formData, "fmCurrentCertificatesFileList");
  },

  /**
   * Set fmRegistrationNumberExistent
   * */
  setRegistrationNumberExistentFeedMills(state, payload) {
    state.formData.fmRegistrationNumberExistent = payload;
  },

  /**
   * Set deletion of fmRegistrationNumberFileList
   * */
  setRegistrationNumberFileListFeedMills(state) {
    Vue.delete(state.formData, "fmRegistrationNumberFileList");
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideFeedMills(state) {
    if (state.formData.fmCurrentCertificatesExistent === true) {
      state.schema.fmCurrentCertificatesLater.hidden = true;
      state.schema.fmCurrentCertificatesFile.hidden = false;
      Vue.delete(state.formData, "fmCurrentCertificatesLater");
    } else if (state.formData.fmCurrentCertificatesExistent === false) {
      state.schema.fmCurrentCertificatesLater.hidden = false;
      state.schema.fmCurrentCertificatesFile.hidden = true;
      if (
        !!state.formData.fmCurrentCertificatesFileList &&
        state.formData.fmCurrentCertificatesFileList.length < 1
      ) {
        Vue.delete(state.formData, "fmCurrentCertificatesFileList");
      }
    }
    if (state.formData.fmCurrentCertificatesExistent === null) {
      state.schema.fmCurrentCertificatesFile.hidden = true;
      state.schema.fmCurrentCertificatesLater.hidden = true;
    }
    if (state.formData.fmRegistrationNumberExistent === true) {
      state.schema.fmRegistrationNumberLater.hidden = true;
      ["fmRegistrationNumber", "fmRegistrationNumberFile"].forEach(
        a => (state.schema[a].hidden = false)
      );
      Vue.delete(state.formData, "fmRegistrationNumberLater");
    } else if (state.formData.fmRegistrationNumberExistent === false) {
      state.schema.fmRegistrationNumberLater.hidden = false;
      ["fmRegistrationNumber", "fmRegistrationNumberFile"].forEach(
        y => (state.schema[y].hidden = true)
      );
      Vue.delete(state.formData, "fmRegistrationNumber");
      if (
        !!state.formData.fmRegistrationNumberFileList &&
        state.formData.fmRegistrationNumberFileList.length < 1
      ) {
        Vue.delete(state.formData, "fmRegistrationNumberFileList");
      }
    }
    if (state.formData.fmRegistrationNumberExistent === null) {
      state.schema.fmRegistrationNumberFile.hidden = true;
      state.schema.fmRegistrationNumberLater.hidden = true;
    }
    if (state.formData.fmProductionSiteChoose === true) {
      state.schema.fmInformation.hidden = true;
      [
        "fmPlantManager",
        "fmPmFirstName",
        "fmPmLastName",
        "fmPmPhone",
        "fmPmMobile",
        "fmPmFax",
        "fmPmEmail",
        "fmStreetNumber",
        "fmPostalCode",
        "fmLocation",
        "fmCountry",
        "fmRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.fmProductionSiteChoose === false) {
      state.schema.fmInformation.hidden = false;
    }
  },

  /**
   * Setter for any status changes.
   * */
  setStatusFeedMills(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setFeedMills(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter for file-objects.
   * */
  setFileFeedMills(state, payload) {
    Vue.set(state.formData, "files", payload);
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for data due to approval.
   * */
  setEditableFeedMills(state, payload) {
    state.editable = payload;
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => FeedMills.vue
   * */
  getDataFeedMills: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => FeedMills.vue
   * */
  getSchemaFeedMills: state => {
    return state.schema;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList. Injected via conf….json-file
   * as a prop. Despite the other getters of the same type,
   * this one is used when several FileSelectList-components
   * are available on the same level. Distinguishes between
   * the different lists with their names, which are set in
   * the conf….json-files.
   * */
  getFilesByFileListNameFeedMills(state) {
    return fileListName =>
      state.formData.files.filter(files => {
        if (files.fileListName === fileListName) {
          return files;
        } else return null;
      });
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getCurrentCertificatesExistentFeedMills(state) {
    return state.formData.fmCurrentCertificatesExistent;
  },

  /**
   * Ignore the unused property-hint!
   *
   * Used in FileSelectList as activator for v-if.
   * Injected via conf….json-file as a prop.
   * Use-case: FileInput is hidden upon user-choice.
   * */
  getRegistrationNumberExistentFeedMills(state) {
    return state.formData.fmRegistrationNumberExistent;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditableFeedMills: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
