/* eslint-disable no-console */
/**
 * Return an array of values
 * that match on a certain key
 *  */
function getValues(obj, key) {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == "object") {
      objects = objects.concat(getValues(obj[i], key));
    } else if (i == key) {
      objects.push(obj[i]);
    }
  }
  return objects;
}

/**
 * Return an array of objects according to key,
 * value, or key and value matching
 *  */
function getObjects(obj, key, val) {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == "object") {
      objects = objects.concat(getObjects(obj[i], key, val));
    } else if ((i == key && obj[i] == val) || (i == key && val == "")) {
      /**
       * If key matches and value matches or if key matches and value is
       * not passed (eliminating the case where key matches but passed
       * value does not)
       *  */
      objects.push(obj);
    } else if (obj[i] == val && key == "") {
      /** Only add if the object is not already in the array */
      if (objects.lastIndexOf(obj) == -1) {
        objects.push(obj);
      }
    }
  }
  return objects;
}

/**
 * Return an array of keys that
 * match on a certain value
 * */
function getKeys(obj, val) {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == "object") {
      objects = objects.concat(getKeys(obj[i], val));
    } else if (obj[i] == val) {
      objects.push(i);
    }
  }
  return objects;
}

/**
 * Parse template literal in json, etc. …
 * const json = {"json": "May the {{lit1}} Parser last for {{lit2}} years!"}
 * const parsed = stringTemplateParser(json.json, {lit1: 'JSON', lit2:100})
 * */

/* eslint-disable no-unused-vars */
function stringTemplateParser(expression, valueObj) {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
  return expression.replace(templateMatcher, (substring, value, index) => {
    value = valueObj[value];
    return value;
  });
}
/* eslint-disable no-unused-vars */

/**
 * Check if a variable is a JavaScript object and nothing else (not an Array, Set, etc.).
 * toString() method returns a string representing the object.
 * Outputs boolean
 * */
function isObject(variable) {
  return Object.prototype.toString.call(variable) === "[object Object]";
}

/**
 * Check if variable is an array and NOT empty.
 *
 * @returns boolean
 * */
function isNotEmptyArray(arr) {
  return !!(Array.isArray(arr) && arr.length);
}

/**
 * Check if array values are keys in an object
 *
 * @param {*} obj
 * @param {string[]} keys
 * @returns boolean
 * */
function hasKeys(obj, keys) {
  for (let i = 0; i !== keys.length; ++i) {
    if (!(keys[i] in obj)) return false;
  }
  return true;
}

/**
 * Check if property exists in object.
 * Regards IE and JS (method naming is not protected!)
 *
 * @returns boolean
 * */
function hasProp(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * Creates unique identifier from date object.
 * Converted to 32bit integer
 * @param {string} str
 * */
function hashCode(str) {
  let hash = 0,
    i,
    chr,
    len;
  if (str.length === 0) return hash;
  for (i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

/**
 * As typeof and instanceof are insufficient to test
 * whether a value is a boolean, a number or a string -
 * typeof only works for primitive booleans, numbers and strings;
 * and instanceof doesn't work for primitive booleans, numbers and string
 * */
function typeOf(value) {
  return Object.prototype.toString.call(value).slice(8, -1);
}

/**
 * @param {string} string
 * @return {string}
 * */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Determine if a string consists of only positive or negative integers.
 *
 * @param {string}  string
 * @return {boolean}
 * */
function stringIsInteger(string) {
  return !!(typeOf(string) === "String" && string.match(/^[-+]?\d+$/));
}

export {
  getValues,
  getObjects,
  getKeys,
  stringTemplateParser,
  isObject,
  isNotEmptyArray,
  hasKeys,
  hasProp,
  hashCode,
  typeOf,
  capitalizeFirstLetter,
  stringIsInteger
};
