import axios from "axios";
import store from "../store";

/**
 * Create axios instance
 * */
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

/**
 * Intercepts each response globally before it is handled by then or catch.
 * */
apiClient.interceptors.response.use(
  async response => {
    if (
      (response.data[0] === "ERROR" &&
        response.data[1] === "invalid usertoken") ||
      (response.data[0] === "ERROR" && response.data[1] === "invalid token") ||
      (response.data[0] === "ERROR" &&
        response.data[1] === "invalid logintoken")
    ) {
      store.commit("global_errorStatus", true, { root: true });
      store.commit(
        "global_error",
        { message: response.data[1] },
        { root: true }
      );
      await store.dispatch("auth/logout", null);
      return response.data[1];
    } else return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default apiClient;
