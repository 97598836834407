import apiClient from "./apiClient";

export default {
  /**
   * GET /getlist/{tokenType}/{listType}/{locale}
   *
   * @param {string} tokenType is dependent on listtype
   * @param {string} listType defines type:
   * userfacilities => sites assigned to the user
   * validfacilitytypes => list of possible site types
   * @param {string} locale
   * */
  getList: function(tokenType, listType, locale = "de") {
    return apiClient.get(
      "/getlist/" + tokenType + "/" + listType + "/" + locale
    );
  },

  /**
   * GET /getmoduledata/{tokenType}/{modulename}
   *
   * @param {string} tokenType defines datatype:
   * user-related data => userToken;
   * site-related data => userToken + facilityToken
   * @param {string} moduleName => module's moduleName
   * */
  getModuleData: function(tokenType, moduleName) {
    return apiClient.get("/getmoduledata/" + tokenType + "/" + moduleName);
  },

  asyncGetModuleData: async function(tokenType, moduleName) {
    return await apiClient.get(
      "/getmoduledata/" + tokenType + "/" + moduleName
    );
  },

  /**
   * GET /deletefile/{tokenType}/{fieldName}/{stableID}/{uID}
   *
   * @param {string} tokenType defines datatype:
   * user-related data => userToken;
   * site-related data => userToken + facilityToken
   * @param {string} fieldName => form's datafield name
   * @param {number} stableID => INT if stable related files (layingFarm) a unique id, else zero
   * @param {number} uID => INT unique fileID as identifier
   *
   * delete file
   * */
  getDeleteFile: function(tokenType, fieldName, stableID, uID) {
    return apiClient.get(
      "/deletefile/" + tokenType + "/" + fieldName + "/" + stableID + "/" + uID
    );
  },

  /**
   * GET /deletefacility/{facilityToken}
   *
   * @param {string} facilityToken
   *
   * deletes a facility and all related data, e.G. sub-locations and files,
   * therefore only applied in module company
   * */
  getDeleteFacility: function(facilityToken) {
    return apiClient.get("/deletefacility/" + facilityToken);
  },

  /**
   * GET /deleteuser/{userToken}/{loginToken}
   *
   * Deletes a company a.k.a. user with all related data, if company
   * is not approved yet. Same for related data/facilities.
   *
   * @param {string} userToken
   * @param {string} loginToken
   */
  getDeleteCompany: function(userToken, loginToken) {
    return apiClient.get("/deleteuser/" + userToken + "/" + loginToken);
  },

  /**
   * GET /submit/{userToken}
   *
   * @param {string} userToken credential to authenticate and identify user
   *
   * sets the submission date
   * */
  getSubmitRegistration: function(userToken) {
    return apiClient.get("/submit/" + userToken);
  },

  /**
   * GET /submissiondate/{userToken}
   *
   * @param {string} userToken credential to authenticate and identify user
   *
   * gets the submission date
   * */
  loadSubmissionDate: function(userToken) {
    return apiClient.get("/submissiondate/" + userToken);
  },

  /**
   * GET /updatesubmissiondate/{userToken}
   *
   * @param {string} userToken credential to authenticate and identify user
   *
   * returns a comma separated list of dates (when application form was updates/resubmitted)
   * */
  loadUpdateSubmissionDate: function(userToken) {
    return apiClient.get("/updatesubmissiondate/" + userToken);
  },

  /**
   * POST /registerlogin -> this is user!
   *
   * @param {Object} userCredentials => object => name, email and locale to register a new user
   * => email: userEmail
   * => name: firstName + ' ' + lastName
   * => locale: userLocale
   *
   * sends pw via email to user from server-side
   * returns $logintoken
   * */
  postRegisterLogin: function(userCredentials) {
    return apiClient.post("/registerlogin", userCredentials);
  },

  /**
   * POST /registeruser/{loginToken} -> this is company! Due to simplicity the name is not changed in backend.
   *
   * @param {string} loginToken
   * @param {object} companyCredentials companyname to register a new company
   * => name: firstName + ' ' + lastName
   *
   * returns userToken (former userToken)
   * */
  postRegisterCompany: function(loginToken, companyCredentials) {
    return apiClient.post("/registeruser/" + loginToken, companyCredentials);
  },

  /**
   * POST /login
   *
   * @param {object} userCredentials username/email and pw to login the user
   * => email: userEmail
   * => pw: userPw
   *
   * returns array on success [0: SUCCESS, 1: loginToken, 2: array with companies, 3: locale]
   * */
  postLogin: function(userCredentials) {
    return apiClient.post("/login", userCredentials);
  },

  /**
   * POST /passwordreset
   *
   * @param {object} userCredentials => username(email)
   *
   * returns array on success [0: SUCCESS]
   * */
  postPasswordResetRequest: function(userCredentials) {
    return apiClient.post("/passwordreset", userCredentials);
  },

  /**
   * POST /confirmpasswordreset
   *
   * @param {object} userHash => hash
   *
   * returns array on success [0: SUCCESS]
   * */
  postConfirmPasswordReset: function(userHash) {
    return apiClient.post("/confirmpasswordreset", userHash);
  },

  /**
   * POST /registerfacility/{userToken}
   *
   * @param userToken => credential to authenticate and identify user
   * @param productionType => productionType.itemValue
   * @param facilityName => facilityName
   *
   * returns facilityToken on success
   * */
  postAddFacility: function(userToken, productionType, facilityName) {
    let facilityPayload = {
      GlobalFacilityTypeID: productionType,
      Name: facilityName
    };
    return apiClient.post("/registerfacility/" + userToken, facilityPayload);
  },

  /**
   * POST /savemoduledata/{tokenType}
   *
   * @param tokenType defines datatype:
   * user-related data => userToken;
   * site-related data => userToken + facilityToken
   * @param moduleName => module's moduleName
   * @param moduleData => module's formData-object content
   * */
  postAddModuleData: function(tokenType, moduleName, moduleData) {
    let modulePayload = {
      modulename: moduleName,
      moduledata: moduleData
    };
    return apiClient.post("/savemoduledata/" + tokenType, modulePayload);
  },

  /**
   * POST /savefile/{tokenType}/{fieldName}/{stableID}/{uID}
   *
   * @param tokenType defines datatype:
   * user-related data => userToken;
   * site-related data => userToken + facilityToken
   * @param fieldName => form's datafield name
   * @param stableID => INT if stable related files (layingFarm) a unique id, else zero
   * @param uID => INT unique fileID as identifier
   * @param fileName => the filename
   * @param fileType => file's datatype
   * @param fileSize => file's size
   * @param fileContent=> file's data base64 encoded
   * */
  postAddFile: function(
    tokenType,
    fieldName,
    stableID,
    uID,
    fileName,
    fileType,
    fileSize,
    fileContent
  ) {
    let filePayload = {
      filename: fileName,
      filetype: fileType,
      filesize: fileSize,
      filecontent: fileContent
    };
    return apiClient.post(
      "/savefile/" + tokenType + "/" + fieldName + "/" + stableID + "/" + uID,
      filePayload
    );
  }
};
