import Vue from "vue";
import Vuetify from "vuetify/lib";
import {
  mdiAccount,
  mdiAccountMultiplePlus,
  mdiAccountPlus,
  mdiBarn,
  mdiCalendar,
  mdiCardAccountDetails,
  mdiCheckCircle,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCheckboxMarkedCircle,
  mdiClose,
  mdiCogTransfer,
  mdiContentSave,
  mdiCorn,
  mdiDelete,
  mdiDoorOpen,
  mdiEgg,
  mdiEggEaster,
  mdiEye,
  mdiEyeOff,
  mdiFactory,
  mdiFountainPenTip,
  mdiHomeCity,
  mdiHomeGroup,
  mdiIdCard,
  mdiInformation,
  mdiLeadPencil,
  mdiLock,
  mdiLogin,
  mdiLogout,
  mdiPackageVariantClosed,
  mdiPlus,
  mdiSend,
  mdiSilo,
  mdiTranslate,
  mdiUpload
} from "@mdi/js";

Vue.use(Vuetify);
Vuetify.config.silent = process.env.NODE_ENV === "production";

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg", //'mdi'
    values: {
      mdiAccount: mdiAccount,
      mdiAccountMultiplePlus: mdiAccountMultiplePlus,
      mdiAccountPlus: mdiAccountPlus,
      mdiBarn: mdiBarn,
      mdiCalendar: mdiCalendar,
      mdiCardAccountDetails: mdiCardAccountDetails,
      mdiCheckCircle: mdiCheckCircle,
      mdiCheckboxBlankOutline: mdiCheckboxBlankOutline,
      mdiCheckboxMarked: mdiCheckboxMarked,
      mdiCheckboxMarkedCircle: mdiCheckboxMarkedCircle,
      mdiClose: mdiClose,
      mdiCogTransfer: mdiCogTransfer,
      mdiContentSave: mdiContentSave,
      mdiCorn: mdiCorn,
      mdiDelete: mdiDelete,
      mdiDoorOpen: mdiDoorOpen,
      mdiEgg: mdiEgg,
      mdiEggEaster: mdiEggEaster,
      mdiEye: mdiEye,
      mdiEyeOff: mdiEyeOff,
      mdiFactory: mdiFactory,
      mdiFountainPenTip: mdiFountainPenTip,
      mdiHomeCity: mdiHomeCity,
      mdiHomeGroup: mdiHomeGroup,
      mdiIdCard: mdiIdCard,
      mdiInformation: mdiInformation,
      mdiLeadPencil: mdiLeadPencil,
      mdiLock: mdiLock,
      mdiLogin: mdiLogin,
      mdiLogout: mdiLogout,
      mdiPackageVariantClosed: mdiPackageVariantClosed,
      mdiPlus: mdiPlus,
      mdiSend: mdiSend,
      mdiSilo: mdiSilo,
      mdiTranslate: mdiTranslate,
      mdiUpload: mdiUpload
    }
  }
});
