import confDealers from "../../conf/confDealers";
import Vue from "vue";
import apiCalls from "@/api/apiCalls";

/* eslint-disable no-console */
const initialFormData = () => {
  return {
    suppliers: [],
    recipients: [],
    files: []
  };
};

const state = () => ({
  moduleName: "dealers",
  formData: {
    suppliers: [],
    recipients: [],
    files: []
  },
  schema: { ...confDealers.schema },
  editable: true,
  status: 0,
  error: null
});

/**
 * status = 0 -> No loading has begun -> null
 * status = 1 -> Loading has started -> Loading
 * status = 2 -> Loading completed successfully -> Success
 * status = 3 -> Loading completed unsuccessfully -> Error
 * */

const actions = {
  /**
   * Updates formData-object upon user input.
   * */
  updateDealers: ({ commit }, payload) => {
    commit("setUpdateDealers", payload);
    commit("setHideDealers");
  },

  /**
   * Add or update dealers' module data.
   * Actual saving functionality.
   * */
  addDealers: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = {
      token: token,
      moduleName: state.moduleName,
      formData: state.formData
    };
    apiCalls.addModuleData(payload);
  },

  /**
   * Load dealers' module data.
   * Done in dealers' view created hook.
   * */
  loadDealers: ({ state, rootState, rootGetters }) => {
    const userToken = rootState.auth.userToken;
    const facilityToken = rootGetters["company/get_singleLocationID"];
    const token = userToken + facilityToken;
    let payload = { token: token, moduleName: state.moduleName };
    apiCalls.getModuleData(payload);
  }
};

const mutations = {
  /**
   * If loadDealers returns "no moduledata found"
   * state is reset to "initialFormData".
   * Done due to the fact that vuex and vue try
   * to reuse every state. This is problematic, when
   * a location/facility is created but not saved with
   * any kind of (even empty) data.
   * */
  setInitialFormDataDealers(state) {
    // Vue.set(state, "formData", initialFormData);
    state.formData = Object.assign({}, initialFormData());
    state.schema = Object.assign(
      {},
      JSON.parse(JSON.stringify({ ...confDealers.schema }))
    );
  },

  /**
   * Setter to update formData-object
   * upon user-input. Event driven.
   * Not used in any api-call.
   * */
  setUpdateDealers(state, payload) {
    state.formData = payload;
  },

  /**
   * Setter to programmatically show or hide
   * form elements upon user's choice.
   * */
  setHideDealers(state) {
    if (state.formData.dProductionSiteChoose === true) {
      state.schema.dInformation.hidden = true;
      [
        "dPlantManager",
        "dPmFirstName",
        "dPmLastName",
        "dPmPhone",
        "dPmMobile",
        "dPmFax",
        "dPmEmail",
        "dStreetNumber",
        "dPostalCode",
        "dLocation",
        "dCountry",
        "dRoute"
      ].forEach(x => Vue.delete(state.formData, `${x}`));
    } else if (state.formData.dProductionSiteChoose === false) {
      state.schema.dInformation.hidden = false;
    }
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter for any status changes.
   * */
  setStatusDealers(state, payload) {
    state.status = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Setter to change formData-object
   * upon any api-call.
   * */
  setDealers(state, payload) {
    state.formData = payload;
  },

  /**
   * Ignore the unused property-hint!
   * Method is used in getModuleData() in apiCalls.js
   *
   * Set editability for data due to approval.
   * */
  setEditableDealers(state, payload) {
    state.editable = payload;
  }
};

const getters = {
  /**
   * Getter for formData-object.
   * Used in views => Dealers.vue
   * */
  getDataDealers: state => {
    return state.formData;
  },

  /**
   * Getter for schema-object from conf….json-file,
   * used to dynamically build the UI from component-configuration
   * declared in .json and injected in FormBuilder.vue
   * Used in views => Dealers.vue
   * */
  getSchemaDealers: state => {
    return state.schema;
  },

  /**
   * Get editability of data (aside locations array)
   * */
  getEditableDealers: state => {
    return state.editable;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
