import { typeOf } from "@/helpers/helpers";

/* eslint-disable no-console */
function alterModuleData(moduleName, moduleData) {
  const animalFarms = ["layingFarm", "rearer", "preraiser"];

  // map old henhouse manufacturer data from free-form-text to object and other stable related stuff
  if (animalFarms.find(elem => elem === moduleName)) {
    let stables =
      moduleName === "layingFarm"
        ? moduleData.lfStables
        : moduleName === "rearer"
        ? moduleData.rStables
        : moduleData.preStables;
    if (stables.length) {
      stables.forEach(el => {
        if (el.fitterAndType && typeOf(el.fitterAndType) === "String") {
          el.fitterAndType = {
            itemText: el.fitterAndType,
            itemValue: "-99" //map old stringdata to -99
          };
        }
        if (el.farmingType) {
          if (
            el.farmingType.itemText.includes(
              "rStablesDialogItemsFarmingTypeItemsItemText1"
            ) ||
            el.farmingType.itemText.includes(
              "preStablesDialogItemsFarmingTypeItemsItemText1"
            )
          ) {
            el.farmingType.itemText = "global.stables.conventional";
          }
          if (
            el.farmingType.itemText.includes(
              "lfStablesDialogItemsFarmingTypeItemsItemText1"
            )
          ) {
            el.farmingType.itemText = "global.stables.barnProduction";
          }
          if (
            el.farmingType.itemText.includes(
              "StablesDialogItemsFarmingTypeItemsItemText2"
            )
          ) {
            el.farmingType.itemText = "global.stables.freeRangeProduction";
          }
          if (
            el.farmingType.itemText.includes(
              "StablesDialogItemsFarmingTypeItemsItemText3"
            )
          ) {
            el.farmingType.itemText = "global.stables.organic";
          }
        }

        if (el.rearingType) {
          if (
            el.rearingType.itemText.includes(
              "StablesDialogItemsRearingTypeItemsItemText1"
            )
          ) {
            el.rearingType.itemText = "global.stables.manurePit";
          }
          if (
            el.rearingType.itemText.includes(
              "StablesDialogItemsRearingTypeItemsItemText2"
            )
          ) {
            el.rearingType.itemText = "global.stables.semiAviarySystem";
          }
          if (
            el.rearingType.itemText.includes(
              "StablesDialogItemsRearingTypeItemsItemText3"
            )
          ) {
            el.rearingType.itemText = "global.stables.aviarySystem";
          }
          if (
            el.rearingType.itemText.includes(
              "StablesDialogItemsRearingTypeItemsItemText4"
            )
          ) {
            el.rearingType.itemText = "global.stables.differentPosture";
          }
        }

        if (el.sex) {
          if (el.sex.itemText.includes("StablesDialogItemsSexItemsItemText1")) {
            el.sex.itemText = "global.stables.male";
          }
          if (el.sex.itemText.includes("StablesDialogItemsSexItemsItemText2")) {
            el.sex.itemText = "global.stables.female";
          }
          if (el.sex.itemText.includes("StablesDialogItemsSexItemsItemText3")) {
            el.sex.itemText = "global.stables.maleFemale";
          }
        }
      });
    }
  }
  return moduleData;
}

export { alterModuleData };
