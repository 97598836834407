import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import confRouterTitles from "../conf/confRouterTitles";

Vue.use(VueRouter);

// /* webpackChunkName: "group-foo" */ see https://router.vuejs.org/guide/advanced/lazy-loading.html

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const login = () => import("../views/Login");
const registration = () => import("../views/Registration");
const userToCompany = () => import("../views/UserToCompany");
const request = () => import("../views/RequestType");
const company = () => import("../views/Company");
const hatchery = () => import("../views/Hatchery");
const preraiser = () => import("../views/Preraiser");
const rearer = () => import("../views/Rearer");
const layingFarm = () => import("../views/LayingFarm");
const homeCompounders = () => import("../views/HomeCompounders");
const packing = () => import("../views/Packing");
const dyehouse = () => import("../views/Dyehouse");
const feedmills = () => import("../views/FeedMills");
const dealers = () => import("../views/Dealers");
const eggFoodFacility = () => import("../views/EggFoodFacility");
const send = () => import("../views/Send");
const fourOhfour = () => import("../views/404");
const passwordReset = () => import("../views/PasswordReset");
const resetLink = () => import("../views/ResetLink");

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      display: false
    }
  },
  {
    path: "/registration",
    name: "registration",
    component: registration,
    meta: {
      display: false
    }
  },
  {
    path: "/passwordreset",
    name: "passwordreset",
    component: passwordReset,
    meta: {
      display: false
    }
  },
  {
    path: "/resetlink/:hash",
    name: "resetlink",
    component: resetLink,
    meta: {
      display: false
    }
  },
  {
    path: "/usercompany",
    name: "usercompany",
    component: userToCompany,
    meta: {
      requiresAuth: true,
      permission: "user",
      display: true,
      title: confRouterTitles.managecompanies,
      icon: "$mdiAccountMultiplePlus",
      showModal: false
    }
  },
  {
    path: "/request",
    name: "request",
    component: request,
    meta: {
      requiresAuth: true,
      permission: "company",
      display: true,
      title: confRouterTitles.request,
      icon: "$mdiDoorOpen"
    }
  },
  {
    path: "/company",
    name: "company",
    components: {
      default: company
    },
    meta: {
      requiresAuth: true,
      permission: "company",
      display: true,
      showModal: false,
      title: confRouterTitles.company,
      icon: "$mdiCardAccountDetails"
    },
    children: [
      {
        path: "hatchery/:uID",
        name: "hatchery",
        components: {
          default: company,
          modalDialog: hatchery
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          type: 2,
          title: confRouterTitles.hatchery,
          icon: "$mdiHomeGroup"
        }
      },
      {
        path: "preraiser/:uID",
        name: "preraiser",
        components: {
          default: company,
          modalDialog: preraiser
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          type: 32,
          title: confRouterTitles.preraiser,
          icon: "$mdiHomeCity"
        }
      },
      {
        path: "rearer/:uID",
        name: "rearer",
        components: {
          default: company,
          modalDialog: rearer
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          type: 1,
          title: confRouterTitles.rearer,
          icon: "$mdiBarn"
        }
      },
      {
        path: "layingfarm/:uID",
        name: "layingfarm",
        components: {
          default: company,
          modalDialog: layingFarm
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          type: 15,
          title: confRouterTitles.layingfarm,
          icon: "$mdiEgg"
        }
      },
      {
        path: "homecompounders/:uID",
        name: "homecompounders",
        components: {
          default: company,
          modalDialog: homeCompounders
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          type: 23,
          title: confRouterTitles.homecompounders,
          icon: "$mdiCorn"
        }
      },
      {
        path: "packing/:uID",
        name: "packing",
        components: {
          default: company,
          modalDialog: packing
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          type: 20,
          title: confRouterTitles.packing,
          icon: "$mdiPackageVariantClosed"
        }
      },
      {
        path: "dyehouse/:uID",
        name: "dyehouse",
        components: {
          default: company,
          modalDialog: dyehouse
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          type: 8,
          title: confRouterTitles.dyehouse,
          icon: "$mdiEggEaster"
        }
      },
      {
        path: "feedmills/:uID",
        name: "feedmills",
        components: {
          default: company,
          modalDialog: feedmills
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          type: 7,
          title: confRouterTitles.feedmills,
          icon: "$mdiSilo"
        }
      },
      {
        path: "dealers/:uID",
        name: "dealers",
        components: {
          default: company,
          modalDialog: dealers
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          /** type: 998 => "dealers" subsumes the
           * GlobalFacilityType IDs 16 ('Agent'),
           * 26 ('Point of sale'), 29 ('Trading firm')
           * and 33 ('Animal Trader')
           * */
          type: 998,
          title: confRouterTitles.dealers,
          icon: "$mdiCogTransfer"
        }
      },
      {
        path: "eggfoodfacility/:uID",
        name: "eggfoodfacility",
        components: {
          default: company,
          modalDialog: eggFoodFacility
        },
        meta: {
          requiresAuth: true,
          permission: "company",
          display: false,
          showModal: true,
          /** type: 999 => "eggFoodFacility" subsumes the
           * GlobalFacilityType IDs 5 ('Egg-product plant')
           * and 19('Food-processing industry')
           * */
          type: 999,
          title: confRouterTitles.eggFoodFacility,
          icon: "$mdiFactory"
        }
      }
    ]
  },
  {
    path: "/send",
    name: "send",
    component: send,
    meta: {
      requiresAuth: true,
      permission: "company",
      display: true,
      title: confRouterTitles.send,
      icon: "$mdiSend"
    }
  },
  {
    path: "*",
    name: "not-found",
    component: fourOhfour,
    meta: {
      display: false
    }
  }
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL, //as not set, default value (see vue-cli/vue-router docs) is used therefore
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (
      store.getters["auth/isAuthenticated"] &&
      localStorage.getItem("token") !== null
    ) {
      store.commit("global_validateStatus", null, { root: true });
      store.commit("global_clearError");
      switch (to.meta.permission) {
        case "user":
          if (store.getters["auth/get_permission"] >= 1) {
            if (to.name === "usercompany") {
              if (from.name === "login") {
                next();
              } else {
                // console.log('from ' + from.name + ' to ' + to.name + ' -> company is now logged out')
                store.dispatch("auth/logoutCompany");
                next();
              }
            } else {
              next();
            }
          } else {
            store.dispatch("auth/logout");
            next("/");
          }
          break;
        case "company":
          if (store.getters["auth/get_permission"] >= 2) {
            // console.log('from ' + from.name + ' to ' + to.name);
            next();
          } else {
            store.dispatch("auth/logoutCompany");
            next("/usercompany");
          }
          break;
      }
    } else {
      store.dispatch("auth/logout");
      next("/");
    }
  } else {
    next();
  }
});

export default router;
